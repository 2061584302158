@import '../../../styles/settings';

.formSectionRow {
  display: grid;
  column-gap: 10px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: minmax(0, 1fr) 55px minmax(0, 1fr);
  border-bottom: 1px solid rgba($kw-colors-sand-dark, 0.2);
  padding: 15px 15px;

  &:nth-child(odd) {
    background: $whitest;
  }
  &:nth-child(even) {
    background: $white;
  }

  .rowLabel {
    display: flex;
    width: fit-content;
    align-self: flex-start;
    margin: 10px 0px 16px;
  }

  .iconNotes {
    margin-left: 10px;
    margin-top: 3px;
  }

  .rowInputs {
    display: grid;
    gap: 10px;

    & div.form-group,
    input {
      width: 100%;
    }
  }

  .questionMark {
    margin: 0px 20px;
    align-self: center;
    color: $kw-colors-sand-dark;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .dualColumn {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .MuiFormLabel-root.MuiInputLabel-root {
    padding-left: 6px;
  }
}
