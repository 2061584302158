@import '../../styles/settings';

.formSection {
  .sectionHeader {
    height: 40px;
    background-color: $kw-colors-sand-dark;
    padding: 10px 15px;

    .sectionTitle {
      color: $whitest;
      font-weight: bold;
      font-family: $font-family-sans;
      font-size: $font-size-base;
      line-height: 19px;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
    }
  }
}

.fieldInputs {
  &[error='true'] {
    border: 1px solid $danger-color;

    &:focus {
      box-shadow: 0 0 0 2px $danger-color;
    }
  }
}

.formSectionRow {
  display: grid;
  column-gap: 10px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: minmax(0, 1fr) 55px minmax(0, 1fr);
  border-bottom: 1px solid rgba($kw-colors-sand-dark, 0.2);
  padding: 15px 15px;

  &:nth-child(odd) {
    background: $whitest;
  }
  &:nth-child(even) {
    background: $white;
  }

  .rowLabel {
    width: calc(50% - 55px);
    max-width: 45%;
    align-self: flex-start;
    margin-top: 10px;
  }

  .questionMark {
    margin: 0px 20px;
    align-self: center;
    color: $kw-colors-sand-dark;

    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.dualColumn {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.rowInputs {
  display: grid;
  gap: 10px;

  & div.form-group,
  input {
    width: 100%;
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  padding-bottom: 19px;
  border-bottom: 1px solid $black;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .fieldsContainer {
    display: grid;
    gap: 10px;
    flex-grow: 1;
  }
}

.arrayButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    height: 54px;
    min-width: 120px;
  }
}
.trashArrayButton {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  button {
    margin-left: 10px;
  }
  button > svg {
    height: 100%;
  }
}
