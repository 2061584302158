@import './settings';

.page-layout {
  padding: 25px 50px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 200px;
    height: 70px;
    padding-bottom: 45px;

    &.bordered {
      margin-bottom: 20px;
      padding-bottom: 25px;
      border-bottom: 1px solid $gray-lighter;
    }

    .header-title {
      .header-breadcrumbs {
      }
      h1 {
        font-size: 38px;
        margin-bottom: 0px;
        line-height: 50px;
      }
    }

    .search-container {
      display: flex;
      position: relative;
      align-items: center;

      input {
        padding-right: 45px;
      }

      button {
        position: absolute;
        right: 0;
        color: black;
        align-self: center;
        height: 70%;
        padding: 10px;
        margin-right: 5px;
      }
    }
  }
}

.action-container-properties {
  display: flex;
  border-width: 1px 0;
  height: 50px;
  margin-bottom: 20px;
  position: relative;
  button {
    border: none;
    height: 48px;
    line-height: 35px;
    margin-left: 12px;
  }
}

.action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px rgb(153, 153, 153, 0.5) solid;
  border-width: 1px 0;
  padding: 5px 30px;
  height: 50px;
  margin-bottom: 20px;
  margin-left: -50px;
  margin-right: -50px;

  button {
    color: #a8947d !important;
    background-color: white;
    border: none;
    height: 35px;
    line-height: 35px;
    margin-right: 5px;
  }
}
