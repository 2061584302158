@import '../../styles/settings';

.assessmentLinkListContainer {
  table {
    width: 100%;
    margin-top: 25px;

    thead {
      border-bottom: 1px solid $black;

      tr {
        th {
          padding: 10px;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;

      .linkItem {
        min-height: 100px;
        border-bottom: 1px solid $sand-dark-opaque;
        cursor: pointer;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
        &:nth-child(even) {
          background-color: $sand-dark-opaque;
        }
        &:hover {
          background-color: $kw-colors-sand-lighter;
        }

        td {
          padding: 10px;
        }
      }
    }
  }
}
