@import '../../styles/_settings';

.claimRequestPopup {
  max-width: 700px;
  display: flex;
  flex-direction: column;
}

.communityClaimsList {
  .loadMoreButton {
    margin: 50px auto;
  }

  .communityClaimItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    min-height: 70px;

    &:first-of-type {
      padding-top: 0px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-lighter;
    }

    .itemInfoContainer {
      display: flex;

      img {
        height: 65px;
        width: 65px;
        padding: 15px 0;
        margin-right: 15px;
        box-sizing: content-box;
      }

      .itemInfo {
        h4 {
          padding-top: 15px;
        }

        p {
          color: $gray-dark;
          span {
            color: #a8947d;
          }
        }

        .infoGrid {
          display: grid;
          grid-template-columns: 80px 1fr;
        }
      }
    }
  }
}

.textArea {
  width: 100%;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: unset !important;

  div {
    width: 100%;
  }
}
.radioLabel {
  text-transform: none !important;
  width: 100%;
}
