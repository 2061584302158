// // scss-lint:disable LeadingZero, UnnecessaryMantissa, HexLength
@use 'sass:math';

//== TYPOGRAPHY SETTINGS ====================
$font-family-sans: 'Maison Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-extended: 'Maison Neue Extended', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-book: 'Maison Neue Book', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-serif: 'Chronicle Display A', 'Chronicle Display B', serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-mono: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace !default;

$font-size-largest: 28px;
$font-size-larger: 25px;
$font-size-large: 17px;

$font-size-base: 14px;

$font-size-small: 12px;
$font-size-smaller: 10px;
$font-size-smallest: 9px;

$line-height-base: 24px;

$letter-spacing-title: 0.08em;

$font-weight-normal: 300;
$font-weight-bold: 500;
$font-weight-light: 200;

$header-font-weight: 500;
$header-font-weight-heavier: 700;
$header-font-weight-lighter: 300;

$font-family-base: $font-family-sans;
$header-font-family-base: $font-family-serif;

//== RESPONSIVE BREAKPOINTS =================
$breakpoint-xs: 360px;
$breakpoint-sm: 640px;
$breakpoint-md: 750px;
// 'Tablet' is our default target
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$contained-xs: 360px;
$contained-sm: 638px;
$contained-md: 988px;
$contained-lg: 1120px;
$contained-xl: 1200px;

//== LAYOUT SETTINGS =========================
$gutter: 24px;
$cell-padding: 4px;
$border-radius-base: 4px;
$heading-margin-bottom: math.div($gutter, 2);
$paragraph-margin-bottom: math.div($gutter, 2);

//== COLORS ==================================
$whitest: #fff;
$white: #fafafa;
$gray-disabled: #f0f0f0;
$gray-lightest: #f3f3f3;
$gray-lighter: #d7d7d7;
$gray-light: #c6c6c6;
$gray-mid: #aaa;
$gray-dark: #999;
$gray-darker: #777;
$gray-darkest: #555;
$black: #222;
$blackest: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$gold: #fcaa67;

$kw-colors-sand-lighter: #f6ece1;
$kw-colors-sand-base: #e4cdb5;
$kw-colors-sand-dark: #ac947a;
$kw-colors-sand-darker: #cab29a;

// Colors variations
$sand-dark-opaque: rgba($kw-colors-sand-dark, 0.2);

$kw-colors-blue: #2c699d;

$color: $black;
$background-color: $whitest;
$link-color: darken($kw-colors-sand-dark, 15);
$link-active-color: desaturate(lighten($link-color, 20), 20);

// links -> blue
// controls -> green
// secondary controls -> red
$accent-color: $link-color;
$accent-highlight: desaturate(lighten($accent-color, 20), 30);
$accent-background: desaturate(lighten($accent-color, 50), 10);

$button-primary-background: $black;
$button-primary-border: $black;
$button-secondary-background: $kw-colors-sand-dark;
$button-secondary-border: $kw-colors-sand-dark;
$button-transparent-background: $white;

$checkbox-color: $white;
$checkbox-border-color: $kw-colors-sand-base;
$checkbox-check-color-accent: $kw-colors-sand-base;

$control-color: darken($kw-colors-sand-dark, 20);
$control-highlight: desaturate(lighten($control-color, 15), 40);
$control-background: desaturate(lighten($control-color, 60), 60);

$listing-modal-bg: $black;
$listing-modal-text: $whitest;

$secondary-color: #757575;
$secondary-highlight: desaturate(lighten($secondary-color, 20), 30);
$secondary-background: desaturate(lighten($secondary-color, 55), 30);

$tag-active-bg: $yellow;
$tag-active-text: $white;

$success-color: $green;
$info-color: $cyan;
$warning-color: $yellow;
$danger-color: $red;
$label-color: $kw-colors-sand-dark;

$translucent-dark-start: rgba(0, 0, 0, 0.3);
$translucent-dark-end: rgba(0, 0, 0, 0);

//== Z-INDEX ======================================================================================
$zindex-base: 1;
$zindex-header: 99;
$zindex-popup: 100;
$zindex-modal: 10000;
