@import '../../styles/_settings';

.form {
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 25px;
  width: 600px;

  .photoInputContainer {
    label {
      text-transform: uppercase;
      color: $kw-colors-sand-dark;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
}

.deleteUserButton {
  position: absolute;
  right: 34px;
}

.containerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.claimForm {
  width: 600px;
  h3 {
    letter-spacing: $letter-spacing-title;
    font-family: $font-family-sans;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.claimedTag {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  b {
    color: $kw-colors-sand-dark;
    font-family: $font-family-sans !important;
    font-weight: $font-weight-bold !important;
    font-size: 14px !important;
  }
}
.removeButton {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
