@import '../../styles/_settings';

.propertiesPercentComplete {
  display: inline-flex;
  margin-left: 15px;
  padding: 0 0 10px;
  .background {
    position: absolute;
    z-index: 1;
    svg {
      height: 30px !important;
      width: 30px !important;
      color: rgba(0, 0, 0, 0.2);
    }
  }
  .foreground {
    position: relative;
    z-index: 2;
    svg {
      height: 30px !important;
      width: 30px !important;
      color: #fff;
    }
  }
  .numberPercent {
    color: #fff;
    margin: 0;
    top: 34px;
    left: 55px;
    right: 0;
    bottom: 35px;
    position: absolute;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}
.percentComplete {
  padding-left: 15px;
  position: relative;
  display: inline-flex;
  width: 175px;
  .background {
    margin-bottom: 15px;
    position: absolute;
    z-index: 1;
    svg {
      height: 30px !important;
      width: 30px !important;
      color: $sand-dark-opaque;
    }
  }
  .foreground {
    position: relative;
    z-index: 2;
    svg {
      height: 30px !important;
      width: 30px !important;
      color: $kw-colors-sand-dark;
    }
  }
  .numberPercent {
    color: #fff;
    margin: 0;
    top: 8px;
    left: 50px;
    right: 0;
    bottom: 0px;
    position: absolute;
    font-family: $font-family-extended;
    font-size: 18px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    align-items: center;
  }
}
