@import '../../styles/_settings';

.accountRequestsList {
  .loadMoreButton {
    margin: 50px auto;
  }

  .accountRequestsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    min-height: 70px;

    &:first-of-type {
      padding-top: 0px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-lighter;
    }

    .actionButtons {
      align-self: flex-start;
    }
    .itemInfoContainer {
      display: flex;

      img {
        height: 65px;
        width: 65px;
        margin-right: 15px;
        box-sizing: content-box;
      }

      .itemInfo {
        h4 {
          font-family: Helvetica, $font-family-sans;
          margin-bottom: 7px;
        }
        p {
          letter-spacing: 0.02em;
          line-height: 0.875rem;
          font-family: Helvetica, $font-family-sans;
          font-weight: 400;
        }
        .infoGrid {
          display: grid;
          grid-template-columns: 115px 1fr;
          gap: 5px;
          margin-top: 10px;
          & p {
            color: $gray-dark;
            font-weight: 500;
            margin: 0px;
          }
          & p:nth-child(odd):after {
            content: ':';
          }
        }
      }
    }
  }
}
