@import '../../../styles/settings';

.container {
  background-color: $whitest;
  min-width: 900px;
  min-height: 300px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;

  .title {
    a:hover,
    a:focus {
      border: none;
      outline: none;
    }
  }

  .description {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    color: $black;
  }

  .tagsContainer {
    display: flex;
    min-height: 160px;
    margin-top: 20px;
    margin-bottom: 32px;

    & > * {
      margin: 0 3px;
    }
  }

  .bottomButton {
    display: flex;
    button {
      margin-left: auto;
    }
  }
}
