@import '../../../styles/settings';

.assignPropertyPage {
  .assignButton {
    height: 40px;
    width: 120px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
