@import '../../styles/_settings';
@import '../../styles/_actionButtons';

.tableContainer {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.infoTable {
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: 2px solid $black;

    tr {
      th {
        text-transform: uppercase;
        text-align: left;
        padding: 12px;
        font-weight: $font-weight-bold;
      }
    }
  }

  tbody {
    background-color: $white;

    tr {
      height: auto;
      min-height: 93px;
      border-bottom: 1px solid $sand-dark-opaque;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:hover {
        background-color: $sand-dark-opaque;
      }

      td {
        padding: 12px;
        vertical-align: middle;
      }
    }
  }
}

// Column-specific styling
.communityLink {
  color: $accent-color;
  font-weight: $font-weight-bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.actionsColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.requesterNameCell {
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-bold;

  span {
    font-size: $font-size-small;
    color: $gray-dark;
  }
}

.communityCell {
  display: flex;
  flex-direction: column;
}

// Hover effect for row
.hoveredRow {
  background-color: rgba($kw-colors-sand-dark, 0.1);
}

// Styling for possible tour dates
.tourDates {
  font-size: $font-size-small;
  color: $gray-darkest;
}

// Message cell with expand/collapse feature
.messageCell {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  .expanded {
    white-space: normal;
    max-height: none;
  }

  .showMore {
    color: $kw-colors-blue;
    cursor: pointer;
    font-size: $font-size-small;
    display: inline-block;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}
