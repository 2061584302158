@import '../../../styles/settings';

.columnRightSection {
  display: flex;
  justify-content: flex-end;
  .sectionsComplete {
    height: 68px;
    width: 157px;
    border-radius: 4px;
    background: #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0 0 48px;
    .statsTitle {
      color: $black;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-family: $font-family-sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
    }
    .statsValue {
      margin: 14px 0 0;
      color: #222;
      font-family: $font-family-sans;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
  .descriptionSection {
    display: flex;
    flex-direction: column;
    .subtitleSection {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
  }
}

.tabContent {
  .header {
    margin-bottom: 30px;
    .subTitle {
      font-family: $font-family-sans;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      line-height: 19px;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      color: $kw-colors-sand-dark;
    }
  }
  .tabDescription {
    color: #999999;
    line-height: 19px;
  }

  .formSection {
    .sectionHeader {
      height: 55px;
      background-color: $kw-colors-sand-dark;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;

      .sectionTitle {
        color: $whitest;
        font-weight: bold;
        font-family: $font-family-sans;
        font-size: $font-size-base;
        line-height: 19px;
        letter-spacing: $letter-spacing-title;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .sectionTitleButton {
        color: $kw-colors-sand-dark;
        font-weight: bold;
        font-family: $font-family-sans;
        font-size: $font-size-base;
        line-height: 19px;
        letter-spacing: $letter-spacing-title;
        text-transform: uppercase;
        display: inline !important;
        justify-content: flex-end;
        margin-left: 20px;
      }
    }
  }

  .fieldInputs {
    &[error='true'] {
      border: 1px solid $danger-color;

      &:focus {
        box-shadow: 0 0 0 2px $danger-color;
      }
    }
  }

  .tabFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .photosTab {
    .photoUpload {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 350px;

      h3 {
        text-align: center;
        margin-top: 120px;
        z-index: 1;
      }

      hr {
        width: 220px;
        margin-top: 15px;
        margin-bottom: 0;
      }

      button {
        width: fit-content;
        margin-top: 20px;
        z-index: 0;
      }

      input {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        padding: 0;
        cursor: pointer;
      }
      .uploadLoaderImage {
        display: flex;
        align-items: center;
        height: 350px;
      }
    }

    .table {
      display: table;
      padding-top: 20px;
      margin-bottom: 30px;
      width: 100%;

      .tableHeader {
        display: table-header-group;
        width: 100%;
        .headerRow {
          display: table-row;

          .columnHeader {
            display: table-cell;
            border-bottom: 1px solid $black;
            font-family: $font-family-sans;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            /* identical to box height */
            letter-spacing: 0.08em;
            text-transform: uppercase;

            color: #222222;
          }
        }
      }
      .tableBody {
        display: table-row-group;
        .tableRow {
          display: table-row;
          height: 105px;
          padding: 10px;

          &:nth-child(even) {
            background-color: $sand-dark-opaque;
            width: 900px;
          }

          label {
            margin-left: 10px;
            //margin-top: 10px;
          }

          .divIconHamburger {
            max-width: 5%;
            display: flex;
            align-items: center;
            align-content: center;
            margin-left: 1%;

            .cellButtonHamburger {
              height: 45px;
              width: 10%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              svg {
                height: 25px;
                width: 25px;
              }
            }
          }
          .cellButtonTrash {
            height: 45px;
            width: 45px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
              height: 15px;
              width: 15px;
            }
          }

          .photoAltText2 {
            width: 99%;
            max-width: 66vw;
            max-height: 50px;
            resize: none;
            padding: 5px 15px;
            line-height: 35px;
            text-align: inherit;
            border: 1.5px solid #d7d7d7;
            background-color: transparent;
            margin-top: 2%;
          }

          .tableCell {
            display: table-cell;
            vertical-align: middle;
            width: 80%;
          }

          .photoCell {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 192px;
              max-height: 136px;
              object-fit: scale-down;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .reactTable {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;

    thead {
      border-bottom: $black 1px solid;
      tr {
        padding: 0 10px 10px 10px;
        justify-content: space-between;

        th {
          color: $black;
          font-size: $font-size-small;
          font-weight: $font-weight-bold;
          letter-spacing: $letter-spacing-title;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;

      .rowItem {
        height: 50px;
        border-bottom: 1px $sand-dark-opaque solid;
        width: 100%;
        padding: 10px;
        justify-content: space-between;
        cursor: pointer;

        &:nth-child(odd) {
          background: $white;
        }
        &:nth-child(even) {
          background: $whitest;
        }

        &:not(.nonClickable):hover {
          background-color: $sand-dark-opaque;
        }

        &.nonClickable {
          cursor: unset;
        }

        td {
          align-self: center;
        }
      }
    }
  }
}
.toolTip {
  svg {
    height: 20px;
    width: 20px;
    color: $kw-colors-sand-dark;
    vertical-align: middle;
  }
}
