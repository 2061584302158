@import '../../../../styles/settings';

.editButton {
  line-height: 54px;

  svg {
    height: 20px;
    width: 20px;
    margin-left: -44px;
    margin-top: 15px;
    float: left;
    color: $kw-colors-sand-darker;
  }
}

.undoButton {
  svg {
    height: 20px;
    width: 20px;
    margin-left: -44px;
    margin-top: 15px;
    float: left;
    color: $kw-colors-sand-darker;
  }
}

.populatedContent {
  min-height: 54px;
  margin: 0;
  font-family: $font-family-sans;
  font-weight: $font-weight-bold;
  font-size: 0.875rem;
  line-height: 1.5;
  display: block;
  color: $gray-dark;
  letter-spacing: normal;
  p {
    line-height: 54px;
  }
}

.arrayTitle {
  color: $black;
}

.overrideContents {
}
.overrideContents > * {
  margin-bottom: 10px;
}
