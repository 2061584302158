@import '../../styles/_settings';

.emptyList {
  margin-top: 25px;
  height: 160px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 3%;
  b {
    font-size: 18px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #222222 !important;
  }
  p {
    font-size: 16px;
    color: #4a4a4a;
  }
}
