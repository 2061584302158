@import '../../styles/_settings';

.propertyListContainer {
  .propertyNameContainer {
    display: flex;
    flex-direction: row;
    color: #999999 !important;

    .propertyNameInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      b {
        font-weight: $font-weight-bold;
        color: $black !important;
      }
    }
  }

  table {
    margin-top: 25px;
    width: 100%;
    color: #999999 !important;

    thead {
      border-bottom: $black 1px solid;
      tr {
        padding: 0 10px 10px 10px;
        justify-content: space-between;

        th {
          color: $black;
          // font-size: $font-size-small;
          // font-weight: $font-weight-bold;
          // letter-spacing: $letter-spacing-title;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;

      .propertyItem {
        height: auto;
        border-bottom: 1px $sand-dark-opaque solid;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          background-color: $sand-dark-opaque;
        }

        td {
          align-self: center;
        }
      }
      .expandableInfoOpen {
        display: flex;
        height: 105px;
        padding: 25px;
        background-color: $sand-dark-opaque;
        align-items: center;
      }

      .expandableInfoClose {
        display: none;
      }
    }
  }

  .paginationControls {
    padding: 0.5rem;
    display: flex;
    gap: 10px;
    align-items: center;

    button {
      font-weight: 900;
      font-size: 1rem;
    }
  }
}

.emptyList {
  margin-top: 25px;
  height: 260px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 3%;
  b {
    font-size: 18px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #222222 !important;
  }
  p {
    font-size: 16px;
    color: #4a4a4a;
  }
}

.propertyCell {
}

.careTypesCell {
  display: inline-flex;
}
