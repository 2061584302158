@import '../../styles/settings';

.footer {
  height: 50px;
  background-color: $black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 100px;

  span {
    color: $whitest;
  }
}
