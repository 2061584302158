@import './settings';

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.actionIconButton {
  color: $kw-colors-sand-darker;
  padding: 5px;

  &:focus {
    outline: none !important;
  }
}

.menuItem {
  font-size: 14px;
  font-weight: 500;
}

.menu {
  min-width: 180px;
}
