@import '../../../../styles/_settings';

.jsonHeader {
  color: $whitest;
  height: 40px;
  background-color: $kw-colors-sand-dark;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-family: $font-family-sans;
  font-size: $font-size-base;
  line-height: 19px;
  letter-spacing: $letter-spacing-title;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.prettyJson {
  display: flex;
  flex-direction: row;
  max-width: 100%;

  .jsonColumn {
    max-width: 50%;
    flex-grow: 1;
  }
}

.loadMoreButton {
  margin: 50px auto 0 auto;
  > svg {
    margin-left: 10px;
  }
}
