@import '../../styles/settings';

.sideMenu {
  display: flex;
  flex-direction: column;
  min-width: 260px;
  background: $black;
  padding: 75px 20px 0;
  position: relative;
  transform: translateX(0%);
  transition: transform 0.5s, min-width 0.5s, visibility 0.3s;
  left: 0;

  .menuItemsContainer {
    position: sticky;
    width: 220px;
    margin-top: -74px;
    top: 74px;

    & > h4 {
      color: $kw-colors-sand-base;
      font-size: 0.875rem;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .menuItem {
      padding: 20px 0;
      border-bottom: 1px solid $gray-darkest;

      &:first-of-type {
        border-top: 1px solid $gray-darkest;
      }

      .nestedMenuItem {
        display: flex;
        flex-direction: column;

        .subItemsContainer {
          padding-top: 10px;
          padding-left: 20px;
          display: flex;
          flex-direction: column;

          & > .menuLink {
            padding: 10px 0;
          }
        }
      }

      .menuLink {
        color: $gray-dark;
        font-size: 1rem;
        line-height: 1.3125rem;
        letter-spacing: 0.02em;

        &:hover:not([data-disabled='true']) {
          color: $gray-lighter;
        }

        &:hover[data-disabled='true'] {
          cursor: default;
        }

        @media (max-height: $breakpoint-lg) {
          line-height: 0.75rem;
        }

        @media (max-height: $breakpoint-md) {
          line-height: 0.65rem;
        }

        @media (max-height: 720px) {
          line-height: 0.5rem;
        }
      }
    }

    .activeLink {
      color: $whitest !important;
      font-weight: 500;
    }
  }
}
