@import '../../styles/_settings';

.claimPopup {
  width: 1000px;
  label {
    background: transparent;
  }
  button {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 40px !important;
    font-size: 14px !important;
  }
  p {
    font-size: 16px !important;
    color: #999999 !important;
  }
  span {
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
  }
}

.squaredCheckbox {
  position: relative;
  display: flex;
  flex-direction: row;
  width: fit-content;
  max-width: 40px;
  .checkboxInput {
    display: none;
  }

  .labelCheckbox {
    cursor: pointer;
    position: relative;
    width: 23px;
    height: 23px;
    top: 0;
    border-radius: 4px;
    border: 1px solid $gray-lighter;
    background: transparent;
  }

  .labelCheckbox:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 7px;
    background: transparent;
    top: 5px;
    left: 5px;
    border: 3px solid #000000;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  .labelCheckbox:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  input[type='checkbox']:checked + .labelCheckbox {
    background: black;
  }

  input[type='checkbox']:checked + .labelCheckbox:after {
    filter: alpha(opacity=100);
    border-color: white;
    opacity: 1;
  }

  input[type='checkbox']:disabled + .labelCheckbox {
    background: grey;
  }

  input[type='checkbox']:disabled + .labelCheckbox:after {
    display: none;
  }

  .labelText {
    color: $black;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
  }
}
