@import '../../styles/_settings';

.button {
  display: block;
  text-align: center;
  letter-spacing: 1.1px;
  padding: 0 24px;
  min-height: 35px;
  border-radius: $border-radius-base;
  font-weight: $font-weight-bold;
  font-family: $font-family-sans;
  font-style: normal;
  font-size: 11px;
  line-height: 0;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;

  &.icon {
    padding: 14px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $control-highlight;
  }

  &:not([disabled]):hover {
    background-color: $whitest;
    box-shadow: 0 0 0 1px rgba($control-color, 0.5);
    color: $black;
    outline: 0;
    transition: 0.2s;
  }

  &.error {
    border: 1px solid $danger-color;

    &:focus {
      box-shadow: 0 0 0 2px $danger-color;
    }
  }

  &.brown {
    background-color: $kw-colors-sand-dark;
    border: none;
    white-space: nowrap;
    color: $whitest;

    &:not([disabled]):hover {
      background-color: $kw-colors-sand-darker;
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      outline: 0;
      transition: 0.2s;
    }
  }

  &.dark {
    color: $white;
    background-color: $button-primary-background;
    border: 1px solid $button-primary-border;

    &:not([disabled]):hover {
      background-color: $whitest;
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      color: $black;
      outline: 0;
      transition: 0.2s;
    }
  }

  &.default {
    border: 1px solid #d7d7d7;
    color: $kw-colors-sand-dark;
    background-color: $whitest;

    &:not([disabled]):hover {
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      color: $kw-colors-sand-darker;
      outline: 0;
      transition: 0.2s;
    }
  }

  &.light {
    border: 1px solid $kw-colors-sand-dark;
    color: $kw-colors-sand-dark;
    background-color: $whitest;

    &:not([disabled]):hover {
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      color: $kw-colors-sand-darker;
      outline: 0;
      transition: 0.2s;
    }
  }

  &.white {
    border: none;
    color: $black;
    background-color: $whitest;

    &:not([disabled]):hover {
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      outline: 0;
      transition: 0.2s;
    }
  }

  &.text {
    background: none;
    border: none;
    line-height: inherit;
    color: $kw-colors-sand-dark;

    &:not([disabled]):hover {
      color: $kw-colors-sand-dark;
      background: none;
      outline: 1px solid $kw-colors-sand-dark;
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      transition: 0.2s;
    }
  }
  &.black {
    color: $white;
    background-color: $button-primary-background;
    border: 1px solid $kw-colors-sand-darker;

    &:not([disabled]):hover {
      color: $white;
      background-color: $button-primary-background;
      outline: 1px solid $kw-colors-sand-dark;
      box-shadow: 0 0 0 1px rgba($control-color, 0.5);
      transition: 0.2s;
    }
  }
  &.black_outlined {
    border: 1px solid $button-primary-border;
    color: $black;
    background-color: $whitest;

    &:not([disabled]):hover {
      box-shadow: 0 0 0 1px rgba($black, 0.5);
      color: $black;
      outline: 0;
      transition: 0.2s;
    }
  }

  &.white_outlined {
    border: 1px solid $white;
    color: $white;
    background-color: transparent;

    &:not([disabled]):hover {
      box-shadow: 0 0 0 1px rgba($whitest, 0.5);
      outline: 0;
      transition: 0.2s;
    }
  }

  &.focus {
    color: $white;
    background: #454545;
    border: 0;
  }
}
