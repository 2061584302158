@import '../../styles/settings';

.promptContainer {
  background-color: $whitest;
  display: flex;
  flex-direction: column;
  height: 200px;
  min-width: 500px;
  position: relative;
  padding: 35px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: $font-family-serif;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    font-size: 32px;
    margin-bottom: 20px;

    .closeButton {
      cursor: pointer;
      color: $kw-colors-sand-dark;
      position: absolute;
      top: 15px;
      right: 20px;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  .bottomButtons {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    white-space: pre;
  }
}
