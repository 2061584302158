// scss-lint:disable ImportantRule, SpaceBeforeBrace, PropertySortOrder, SingleLinePerSelector, SpaceAfterPropertyColon, LeadingZero

@import './settings';

.text-small {
  font-size: $font-size-small;
}

.text-larger {
  font-size: $font-size-larger;
}

.touchable,
.clickable {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sticky-top {
  position: sticky;
  top: 0;
}

.sticky-left {
  position: sticky;
  left: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.admin {
  color: $red;

  a,
  a:href,
  a:visited {
    color: $red !important;
  }
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}
.float-none {
  float: none;
}

@media (min-width: $breakpoint-lg) {
  .float-lg-right {
    float: right !important;
  }
  .float-lg-left {
    float: left !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (max-width: $breakpoint-sm) {
  .float-sm-right {
    float: right !important;
  }
  .float-sm-left {
    float: left !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

.clear {
  clear: both;
}

.z-base {
  z-index: $zindex-base;
}
.z-header {
  z-index: $zindex-header;
}
.z-fixed {
  z-index: $zindex-header + 1;
}
.z-popup {
  z-index: $zindex-popup;
}
.z-modal {
  z-index: $zindex-modal;
}

.normal-text {
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
  font-weight: $font-weight-normal !important;
  line-height: $line-height-base !important;
}

//== COLORS =======================================================================================
.text-accent {
  color: $accent-color;
}
.text-control {
  color: $control-color;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-label {
  color: $label-color !important;
  font-size: small;
}

.text-whitest {
  color: $whitest;
}
.text-white {
  color: $white;
}
.text-gray-lightest {
  color: $gray-lightest;
}
.text-gray-lighter {
  color: $gray-lighter;
}
.text-gray-light {
  color: $gray-light;
}
.text-gray-mid {
  color: $gray-mid;
}
.text-gray-dark {
  color: $gray-dark;
}
.text-gray-darker {
  color: $gray-dark;
}
.text-gray-darkest {
  color: $gray-dark;
}
.text-black {
  color: $black !important ;
}
.text-blackest {
  color: $blackest;
}

.text-success {
  color: $success-color;
}
.text-info {
  color: $info-color;
}
.text-warning {
  color: $warning-color;
}
.text-danger {
  color: $danger-color;
}

.bg-accent {
  background-color: $accent-color !important;
}
.bg-control {
  background-color: $control-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-whitest {
  background-color: $whitest !important;
}
.bg-white {
  background-color: $white !important;
}
.bg-gray-lightest {
  background-color: $gray-lightest !important;
}
.bg-gray-lighter {
  background-color: $gray-lighter;
}
.bg-gray-light {
  background-color: $gray-light !important;
}
.bg-gray-mid {
  background-color: $gray-mid !important;
}
.bg-gray-dark {
  background-color: $gray-dark !important;
}
.bg-gray-darker {
  background-color: $gray-dark !important;
}
.bg-gray-darkest {
  background-color: $gray-dark !important;
}
.bg-black {
  background-color: $black !important;
}
.bg-blackest {
  background-color: $blackest !important;
}

.bg-success {
  background-color: $success-color !important;
}
.bg-info {
  background-color: $info-color !important;
}
.bg-warning {
  background-color: $warning-color !important;
}
.bg-danger {
  background-color: $danger-color !important;
}

.bc-accent {
  border-color: $accent-color !important;
}

.bc-control {
  border-color: $control-color !important;
  border-left-color: $control-color !important;
  border-right-color: $control-color !important;
  border-top-color: $control-color !important;
  border-bottom-color: $control-color !important;
}

.bc-secondary {
  border-color: $secondary-color !important;
}

.bc-whitest {
  border-color: $whitest !important;
}
.bc-white {
  border-color: $white !important;
}
.bc-gray-lightest {
  border-color: $gray-lightest !important;
}
.bc-gray-lighter {
  border-color: $gray-lighter !important;
}
.bc-gray-light {
  border-color: $gray-light !important;
}
.bc-gray-mid {
  border-color: $gray-mid !important;
}
.bc-gray-dark {
  border-color: $gray-dark !important;
}
.bc-gray-darker {
  border-color: $gray-dark !important;
}
.bc-gray-darkest {
  border-color: $gray-dark !important;
}
.bc-black {
  border-color: $black !important;
}
.bc-blackest {
  border-color: $blackest !important;
}

.bc-success {
  border-color: $success-color !important;
}
.bc-info {
  border-color: $info-color !important;
}
.bc-warning {
  border-color: $warning-color !important;
}
.bc-danger {
  border-color: $danger-color !important;
}

//== PADDING ======================================================================================
.p-0 {
  padding: 0;
}
.p-25 {
  padding: 0.25 * $gutter;
}
.p-50 {
  padding: 0.5 * $gutter;
}
.p-75 {
  padding: 0.75 * $gutter;
}
.p-100 {
  padding: 1 * $gutter;
}
.p-150 {
  padding: 1.5 * $gutter;
}
.p-200 {
  padding: 2 * $gutter;
}
.p-300 {
  padding: 3 * $gutter;
}
.p-400 {
  padding: 4 * $gutter;
}

.pt-0 {
  padding-top: 0;
}
.pt-25 {
  padding-top: 0.25 * $gutter;
}
.pt-50 {
  padding-top: 0.5 * $gutter;
}
.pt-75 {
  padding-top: 0.75 * $gutter;
}
.pt-100 {
  padding-top: 1 * $gutter;
}
.pt-150 {
  padding-top: 1.5 * $gutter;
}
.pt-200 {
  padding-top: 2 * $gutter;
}
.pt-300 {
  padding-top: 3 * $gutter;
}
.pt-400 {
  padding-top: 4 * $gutter;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-25 {
  padding-bottom: 0.25 * $gutter;
}
.pb-50 {
  padding-bottom: 0.5 * $gutter;
}
.pb-75 {
  padding-bottom: 0.75 * $gutter;
}
.pb-100 {
  padding-bottom: 1 * $gutter;
}
.pb-150 {
  padding-bottom: 1.5 * $gutter;
}
.pb-200 {
  padding-bottom: 2 * $gutter;
}
.pb-300 {
  padding-bottom: 3 * $gutter;
}
.pb-400 {
  padding-bottom: 4 * $gutter;
}

.pl-0 {
  padding-left: 0;
}
.pl-25 {
  padding-left: 0.25 * $gutter;
}
.pl-50 {
  padding-left: 0.5 * $gutter;
}
.pl-75 {
  padding-left: 0.75 * $gutter;
}
.pl-100 {
  padding-left: 1 * $gutter;
}
.pl-150 {
  padding-left: 1.5 * $gutter;
}
.pl-200 {
  padding-left: 2 * $gutter;
}
.pl-300 {
  padding-left: 3 * $gutter;
}
.pl-400 {
  padding-left: 4 * $gutter;
}

.pr-0 {
  padding-right: 0;
}
.pr-25 {
  padding-right: 0.25 * $gutter;
}
.pr-50 {
  padding-right: 0.5 * $gutter;
}
.pr-75 {
  padding-right: 0.75 * $gutter;
}
.pr-100 {
  padding-right: 1 * $gutter;
}
.pr-150 {
  padding-right: 1.5 * $gutter;
}
.pr-200 {
  padding-right: 2 * $gutter;
}
.pr-300 {
  padding-right: 3 * $gutter;
}
.pr-400 {
  padding-right: 4 * $gutter;
}

@media (max-width: $breakpoint-sm) {
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-25 {
    padding: 0.25 * $gutter !important;
  }
  .p-sm-50 {
    padding: 0.5 * $gutter !important;
  }
  .p-sm-75 {
    padding: 0.75 * $gutter !important;
  }
  .p-sm-100 {
    padding: 1 * $gutter !important;
  }
  .p-sm-150 {
    padding: 1.5 * $gutter !important;
  }
  .p-sm-200 {
    padding: 2 * $gutter !important;
  }
  .p-sm-300 {
    padding: 3 * $gutter !important;
  }
  .p-sm-400 {
    padding: 4 * $gutter !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-25 {
    padding-top: 0.25 * $gutter !important;
  }
  .pt-sm-50 {
    padding-top: 0.5 * $gutter !important;
  }
  .pt-sm-75 {
    padding-top: 0.75 * $gutter !important;
  }
  .pt-sm-100 {
    padding-top: 1 * $gutter !important;
  }
  .pt-sm-150 {
    padding-top: 1.5 * $gutter !important;
  }
  .pt-sm-200 {
    padding-top: 2 * $gutter !important;
  }
  .pt-sm-300 {
    padding-top: 3 * $gutter !important;
  }
  .pt-sm-400 {
    padding-top: 4 * $gutter !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-25 {
    padding-bottom: 0.25 * $gutter !important;
  }
  .pb-sm-50 {
    padding-bottom: 0.5 * $gutter !important;
  }
  .pb-sm-75 {
    padding-bottom: 0.75 * $gutter !important;
  }
  .pb-sm-100 {
    padding-bottom: 1 * $gutter !important;
  }
  .pb-sm-150 {
    padding-bottom: 1.5 * $gutter !important;
  }
  .pb-sm-200 {
    padding-bottom: 2 * $gutter !important;
  }
  .pb-sm-300 {
    padding-bottom: 3 * $gutter !important;
  }
  .pb-sm-400 {
    padding-bottom: 4 * $gutter !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-25 {
    padding-left: 0.25 * $gutter !important;
  }
  .pl-sm-50 {
    padding-left: 0.5 * $gutter !important;
  }
  .pl-sm-75 {
    padding-left: 0.75 * $gutter !important;
  }
  .pl-sm-100 {
    padding-left: 1 * $gutter !important;
  }
  .pl-sm-150 {
    padding-left: 1.5 * $gutter !important;
  }
  .pl-sm-200 {
    padding-left: 2 * $gutter !important;
  }
  .pl-sm-300 {
    padding-left: 3 * $gutter !important;
  }
  .pl-sm-400 {
    padding-left: 4 * $gutter !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-25 {
    padding-right: 0.25 * $gutter !important;
  }
  .pr-sm-50 {
    padding-right: 0.5 * $gutter !important;
  }
  .pr-sm-75 {
    padding-right: 0.75 * $gutter !important;
  }
  .pr-sm-100 {
    padding-right: 1 * $gutter !important;
  }
  .pr-sm-150 {
    padding-right: 1.5 * $gutter !important;
  }
  .pr-sm-200 {
    padding-right: 2 * $gutter !important;
  }
  .pr-sm-300 {
    padding-right: 3 * $gutter !important;
  }
  .pr-sm-400 {
    padding-right: 4 * $gutter !important;
  }
}

//== MARGINS ======================================================================================

.m-0 {
  margin: 0 !important;
}
.m-25 {
  margin: 0.25 * $gutter !important;
}
.m-50 {
  margin: 0.5 * $gutter !important;
}
.m-75 {
  margin: 0.75 * $gutter !important;
}
.m-100 {
  margin: 1 * $gutter !important;
}
.m-150 {
  margin: 1.5 * $gutter !important;
}
.m-200 {
  margin: 2 * $gutter !important;
}
.m-300 {
  margin: 3 * $gutter !important;
}
.m-400 {
  margin: 4 * $gutter !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-25 {
  margin-top: 0.25 * $gutter !important;
}
.mt-50 {
  margin-top: 0.5 * $gutter !important;
}
.mt-75 {
  margin-top: 0.75 * $gutter !important;
}
.mt-100 {
  margin-top: 1 * $gutter !important;
}
.mt-150 {
  margin-top: 1.5 * $gutter !important;
}
.mt-200 {
  margin-top: 2 * $gutter !important;
}
.mt-300 {
  margin-top: 3 * $gutter !important;
}
.mt-400 {
  margin-top: 4 * $gutter !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-25 {
  margin-bottom: 0.25 * $gutter !important;
}
.mb-50 {
  margin-bottom: 0.5 * $gutter !important;
}
.mb-75 {
  margin-bottom: 0.75 * $gutter !important;
}
.mb-100 {
  margin-bottom: 1 * $gutter !important;
}
.mb-150 {
  margin-bottom: 1.5 * $gutter !important;
}
.mb-200 {
  margin-bottom: 80px !important;
}
.mb-300 {
  margin-bottom: 3 * $gutter !important;
}
.mb-400 {
  margin-bottom: 4 * $gutter !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-25 {
  margin-left: 0.25 * $gutter !important;
}
.ml-50 {
  margin-left: 0.5 * $gutter !important;
}
.ml-75 {
  margin-left: 0.75 * $gutter !important;
}
.ml-100 {
  margin-left: 1 * $gutter !important;
}
.ml-150 {
  margin-left: 1.5 * $gutter !important;
}
.ml-200 {
  margin-left: 2 * $gutter !important;
}
.ml-300 {
  margin-left: 3 * $gutter !important;
}
.ml-400 {
  margin-left: 4 * $gutter !important;
}
.ml-auto {
  margin-left: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-25 {
  margin-right: 0.25 * $gutter !important;
}
.mr-50 {
  margin-right: 0.5 * $gutter !important;
}
.mr-75 {
  margin-right: 0.75 * $gutter !important;
}
.mr-100 {
  margin-right: 1 * $gutter !important;
}
.mr-150 {
  margin-right: 1.5 * $gutter !important;
}
.mr-200 {
  margin-right: 2 * $gutter !important;
}
.mr-300 {
  margin-right: 3 * $gutter !important;
}
.mr-400 {
  margin-right: 4 * $gutter !important;
}
.mr-auto {
  margin-right: auto !important;
}

@media (min-width: $breakpoint-lg) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-25 {
    margin: 0.25 * $gutter !important;
  }
  .m-lg-50 {
    margin: 0.5 * $gutter !important;
  }
  .m-lg-75 {
    margin: 0.75 * $gutter !important;
  }
  .m-lg-100 {
    margin: 1 * $gutter !important;
  }
  .m-lg-150 {
    margin: 1.5 * $gutter !important;
  }
  .m-lg-200 {
    margin: 2 * $gutter !important;
  }
  .m-lg-300 {
    margin: 3 * $gutter !important;
  }
  .m-lg-400 {
    margin: 4 * $gutter !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-25 {
    margin-top: 0.25 * $gutter !important;
  }
  .mt-lg-50 {
    margin-top: 0.5 * $gutter !important;
  }
  .mt-lg-75 {
    margin-top: 0.75 * $gutter !important;
  }
  .mt-lg-100 {
    margin-top: 1 * $gutter !important;
  }
  .mt-lg-150 {
    margin-top: 1.5 * $gutter !important;
  }
  .mt-lg-200 {
    margin-top: 2 * $gutter !important;
  }
  .mt-lg-300 {
    margin-top: 3 * $gutter !important;
  }
  .mt-lg-400 {
    margin-top: 4 * $gutter !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-25 {
    margin-bottom: 0.25 * $gutter !important;
  }
  .mb-lg-50 {
    margin-bottom: 0.5 * $gutter !important;
  }
  .mb-lg-75 {
    margin-bottom: 0.75 * $gutter !important;
  }
  .mb-lg-100 {
    margin-bottom: 1 * $gutter !important;
  }
  .mb-lg-150 {
    margin-bottom: 1.5 * $gutter !important;
  }
  .mb-lg-200 {
    margin-bottom: 2 * $gutter !important;
  }
  .mb-lg-300 {
    margin-bottom: 3 * $gutter !important;
  }
  .mb-lg-400 {
    margin-bottom: 4 * $gutter !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-25 {
    margin-left: 0.25 * $gutter !important;
  }
  .ml-lg-50 {
    margin-left: 0.5 * $gutter !important;
  }
  .ml-lg-75 {
    margin-left: 0.75 * $gutter !important;
  }
  .ml-lg-100 {
    margin-left: 1 * $gutter !important;
  }
  .ml-lg-150 {
    margin-left: 1.5 * $gutter !important;
  }
  .ml-lg-200 {
    margin-left: 2 * $gutter !important;
  }
  .ml-lg-300 {
    margin-left: 3 * $gutter !important;
  }
  .ml-lg-400 {
    margin-left: 4 * $gutter !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-25 {
    margin-right: 0.25 * $gutter !important;
  }
  .mr-lg-50 {
    margin-right: 0.5 * $gutter !important;
  }
  .mr-lg-75 {
    margin-right: 0.75 * $gutter !important;
  }
  .mr-lg-100 {
    margin-right: 1 * $gutter !important;
  }
  .mr-lg-150 {
    margin-right: 1.5 * $gutter !important;
  }
  .mr-lg-200 {
    margin-right: 2 * $gutter !important;
  }
  .mr-lg-300 {
    margin-right: 3 * $gutter !important;
  }
  .mr-lg-400 {
    margin-right: 4 * $gutter !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
}

@media (max-width: $breakpoint-sm) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-25 {
    margin: 0.25 * $gutter !important;
  }
  .m-sm-50 {
    margin: 0.5 * $gutter !important;
  }
  .m-sm-75 {
    margin: 0.75 * $gutter !important;
  }
  .m-sm-100 {
    margin: 1 * $gutter !important;
  }
  .m-sm-150 {
    margin: 1.5 * $gutter !important;
  }
  .m-sm-200 {
    margin: 2 * $gutter !important;
  }
  .m-sm-300 {
    margin: 3 * $gutter !important;
  }
  .m-sm-400 {
    margin: 4 * $gutter !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-25 {
    margin-top: 0.25 * $gutter !important;
  }
  .mt-sm-50 {
    margin-top: 0.5 * $gutter !important;
  }
  .mt-sm-75 {
    margin-top: 0.75 * $gutter !important;
  }
  .mt-sm-100 {
    margin-top: 1 * $gutter !important;
  }
  .mt-sm-150 {
    margin-top: 1.5 * $gutter !important;
  }
  .mt-sm-200 {
    margin-top: 2 * $gutter !important;
  }
  .mt-sm-300 {
    margin-top: 3 * $gutter !important;
  }
  .mt-sm-400 {
    margin-top: 4 * $gutter !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-25 {
    margin-bottom: 0.25 * $gutter !important;
  }
  .mb-sm-50 {
    margin-bottom: 0.5 * $gutter !important;
  }
  .mb-sm-75 {
    margin-bottom: 0.75 * $gutter !important;
  }
  .mb-sm-100 {
    margin-bottom: 1 * $gutter !important;
  }
  .mb-sm-150 {
    margin-bottom: 1.5 * $gutter !important;
  }
  .mb-sm-200 {
    margin-bottom: 2 * $gutter !important;
  }
  .mb-sm-300 {
    margin-bottom: 3 * $gutter !important;
  }
  .mb-sm-400 {
    margin-bottom: 4 * $gutter !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-25 {
    margin-left: 0.25 * $gutter !important;
  }
  .ml-sm-50 {
    margin-left: 0.5 * $gutter !important;
  }
  .ml-sm-75 {
    margin-left: 0.75 * $gutter !important;
  }
  .ml-sm-100 {
    margin-left: 1 * $gutter !important;
  }
  .ml-sm-150 {
    margin-left: 1.5 * $gutter !important;
  }
  .ml-sm-200 {
    margin-left: 2 * $gutter !important;
  }
  .ml-sm-300 {
    margin-left: 3 * $gutter !important;
  }
  .ml-sm-400 {
    margin-left: 4 * $gutter !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-25 {
    margin-right: 0.25 * $gutter !important;
  }
  .mr-sm-50 {
    margin-right: 0.5 * $gutter !important;
  }
  .mr-sm-75 {
    margin-right: 0.75 * $gutter !important;
  }
  .mr-sm-100 {
    margin-right: 1 * $gutter !important;
  }
  .mr-sm-150 {
    margin-right: 1.5 * $gutter !important;
  }
  .mr-sm-200 {
    margin-right: 2 * $gutter !important;
  }
  .mr-sm-300 {
    margin-right: 3 * $gutter !important;
  }
  .mr-sm-400 {
    margin-right: 4 * $gutter !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
}

//== BORDERS ======================================================================================
.b-1,
.b-1-accent,
.b-accent {
  border: 1px solid $accent-color;
}
.b-2,
.b-2-accent {
  border: 2px solid $accent-color;
}
.bt-1,
.bt-1-accent {
  border-top: 1px solid $accent-color;
}
.bt-2,
.bt-2-accent {
  border-top: 2px solid $accent-color;
}
.br-1,
.br-1-accent {
  border-right: 1px solid $accent-color;
}
.br-2,
.br-2-accent {
  border-right: 2px solid $accent-color;
}
.bb-1,
.bb-1-accent {
  border-bottom: 1px solid $accent-color;
}
.bb-2,
.bb-2-accent {
  border-bottom: 2px solid $accent-color;
}
.bl-1,
.bl-1-accent {
  border-left: 1px solid $accent-color;
}
.bl-2,
.bl-2-accent {
  border-left: 2px solid $accent-color;
}

.b-1-control,
.b-control {
  border: 1px solid $control-color;
}
.b-2-control {
  border: 2px solid $control-color;
}
.bt-1-control {
  border-top: 1px solid $control-color;
}
.bt-2-control {
  border-top: 2px solid $control-color;
}
.br-1-control {
  border-right: 1px solid $control-color;
}
.br-2-control {
  border-right: 2px solid $control-color;
}
.bb-1-control {
  border-bottom: 1px solid $control-color;
}
.bb-2-control {
  border-bottom: 2px solid $control-color;
}
.bl-1-control {
  border-left: 1px solid $control-color;
}
.bl-2-control {
  border-left: 2px solid $control-color;
}

.b-1-secondary,
.b-secondary {
  border: 1px solid $secondary-color;
}
.b-2-secondary {
  border: 2px solid $secondary-color;
}
.bt-1-secondary {
  border-top: 1px solid $secondary-color;
}
.bt-2-secondary {
  border-top: 2px solid $secondary-color;
}
.br-1-secondary {
  border-right: 1px solid $secondary-color;
}
.br-2-secondary {
  border-right: 2px solid $secondary-color;
}
.bb-1-secondary {
  border-bottom: 1px solid $secondary-color;
}
.bb-2-secondary {
  border-bottom: 2px solid $secondary-color;
}
.bl-1-secondary {
  border-left: 1px solid $secondary-color;
}
.bl-2-secondary {
  border-left: 2px solid $secondary-color;
}

.b-1,
.b-1-gray,
.b-gray {
  border: 1px solid $gray-light;
}
.b-2,
.b-2-gray {
  border: 2px solid $gray-light;
}
.bt-1,
.bt-1-gray {
  border-top: 1px solid $gray-light;
}
.bt-2,
.bt-2-gray {
  border-top: 2px solid $gray-light;
}
.br-1,
.br-1-gray {
  border-right: 1px solid $gray-light;
}
.br-2,
.br-2-gray {
  border-right: 2px solid $gray-light;
}
.bb-1,
.bb-1-gray {
  border-bottom: 1px solid $gray-light;
}
.bb-2,
.bb-2-gray {
  border-bottom: 2px solid $gray-light;
}
.bl-1,
.bl-1-gray {
  border-left: 1px solid $gray-light;
}
.bl-2,
.bl-2-gray {
  border-left: 2px solid $gray-light;
}

.b-accent-light {
  border: 1px solid rgba($accent-color, 0.3);
}

.b-control-light {
  border: 1px solid rgba($control-color, 0.3);
}

.b-secondary-light {
  border: 1px solid rgba($secondary-color, 0.3);
}

.b-tertiary {
  border-top: 1px solid $gray-lighter;
}

//== DISPLAY ======================================================================================
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}

@media (max-width: $breakpoint-sm) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
}

@media (max-width: $breakpoint-sm) and (min-width: $breakpoint-xs + 1) {
  .d-smonly-none {
    display: none !important;
  }
  .d-smonly-block {
    display: block !important;
  }
  .d-smonly-inline {
    display: inline !important;
  }
  .d-smonly-inline-block {
    display: inline-block !important;
  }
  .d-smonly-flex {
    display: flex !important;
  }
  .d-smonly-grid {
    display: grid !important;
  }
}

@media (max-width: $breakpoint-xs) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-grid {
    display: grid !important;
  }
  .d-xsonly-none {
    display: none !important;
  }
  .d-xsonly-block {
    display: block !important;
  }
  .d-xsonly-inline {
    display: inline !important;
  }
  .d-xsonly-inline-block {
    display: inline-block !important;
  }
  .d-xsonly-flex {
    display: flex !important;
  }
  .d-xsonly-grid {
    display: grid !important;
  }
}

@media (max-width: $breakpoint-lg) and (min-width: $breakpoint-sm + 1) {
  .d-mdonly-none {
    display: none !important;
  }
  .d-mdonly-block {
    display: block !important;
  }
  .d-mdonly-inline {
    display: inline !important;
  }
  .d-mdonly-inline-block {
    display: inline-block !important;
  }
  .d-mdonly-flex {
    display: flex !important;
  }
  .d-mdonly-grid {
    display: grid !important;
  }
}

@media (min-width: $breakpoint-lg) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
}

@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl - 1) {
  .d-lgonly-none {
    display: none !important;
  }
  .d-lgonly-block {
    display: block !important;
  }
  .d-lgonly-inline {
    display: inline !important;
  }
  .d-lgonly-inline-block {
    display: inline-block !important;
  }
  .d-lgonly-flex {
    display: flex !important;
  }
  .d-lgonly-grid {
    display: grid !important;
  }
}

@media (min-width: $breakpoint-xl) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xlonly-none {
    display: none !important;
  }
  .d-xlonly-block {
    display: block !important;
  }
  .d-xlonly-inline {
    display: inline !important;
  }
  .d-xlonly-inline-block {
    display: inline-block !important;
  }
  .d-xlonly-flex {
    display: flex !important;
  }
  .d-xlonly-grid {
    display: grid !important;
  }
}

//== WIDTH ========================================================================================
.w-auto {
  width: auto;
}
.w-0 {
  width: 0%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-66 {
  width: 66%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 33%;
}
.w-90 {
  width: 33%;
}
.w-100 {
  width: 100%;
}

.w-10px {
  width: 10px;
}
.w-20px {
  width: 20px;
}
.w-25px {
  width: 25px;
}
.w-30px {
  width: 30px;
}
.w-40px {
  width: 40px;
}
.w-50px {
  width: 50px;
}
.w-60px {
  width: 60px;
}
.w-70px {
  width: 70px;
}
.w-75px {
  width: 75px;
}
.w-80px {
  width: 80px;
}
.w-90px {
  width: 90px;
}
.w-100px {
  width: 100px;
}
.w-150px {
  width: 150px;
}
.w-200px {
  width: 200px;
}

@media (max-width: $breakpoint-sm) {
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-0 {
    width: 0% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-33 {
    width: 33% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-66 {
    width: 66% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 33% !important;
  }
  .w-sm-90 {
    width: 33% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}

//== FLEX COMBOS ==================================================================================
.flex-with-gutter {
  display: flex;
  margin-left: -$gutter;
  margin-bottom: -$gutter;

  > div {
    margin-left: $gutter;
    margin-bottom: $gutter;
  }
}

.flex-0 {
  flex-grow: 0;
}
.flex-10 {
  flex-grow: 10;
}
.flex-20 {
  flex-grow: 20;
}
.flex-25 {
  flex-grow: 25;
}
.flex-30 {
  flex-grow: 30;
}
.flex-33 {
  flex-grow: 33;
}
.flex-40 {
  flex-grow: 40;
}
.flex-50 {
  flex-grow: 50;
}
.flex-60 {
  flex-grow: 60;
}
.flex-66 {
  flex-grow: 66;
}
.flex-70 {
  flex-grow: 70;
}
.flex-75 {
  flex-grow: 75;
}
.flex-80 {
  flex-grow: 80;
}
.flex-90 {
  flex-grow: 90;
}
.flex-100 {
  flex-grow: 100;
}

@media (max-width: $breakpoint-sm) {
  .flex-sm-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-10 {
    flex-grow: 10 !important;
  }
  .flex-sm-20 {
    flex-grow: 20 !important;
  }
  .flex-sm-25 {
    flex-grow: 25 !important;
  }
  .flex-sm-30 {
    flex-grow: 30 !important;
  }
  .flex-sm-33 {
    flex-grow: 33 !important;
  }
  .flex-sm-40 {
    flex-grow: 40 !important;
  }
  .flex-sm-50 {
    flex-grow: 50 !important;
  }
  .flex-sm-60 {
    flex-grow: 60 !important;
  }
  .flex-sm-66 {
    flex-grow: 66 !important;
  }
  .flex-sm-70 {
    flex-grow: 70 !important;
  }
  .flex-sm-75 {
    flex-grow: 75 !important;
  }
  .flex-sm-80 {
    flex-grow: 33 !important;
  }
  .flex-sm-90 {
    flex-grow: 33 !important;
  }
  .flex-sm-100 {
    flex-grow: 100 !important;
  }
}

@media (min-width: $breakpoint-lg) {
  .flex-lg-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-10 {
    flex-grow: 10 !important;
  }
  .flex-lg-20 {
    flex-grow: 20 !important;
  }
  .flex-lg-25 {
    flex-grow: 25 !important;
  }
  .flex-lg-30 {
    flex-grow: 30 !important;
  }
  .flex-lg-33 {
    flex-grow: 33 !important;
  }
  .flex-lg-40 {
    flex-grow: 40 !important;
  }
  .flex-lg-50 {
    flex-grow: 50 !important;
  }
  .flex-lg-60 {
    flex-grow: 60 !important;
  }
  .flex-lg-66 {
    flex-grow: 66 !important;
  }
  .flex-lg-70 {
    flex-grow: 70 !important;
  }
  .flex-lg-75 {
    flex-grow: 75 !important;
  }
  .flex-lg-80 {
    flex-grow: 33 !important;
  }
  .flex-lg-90 {
    flex-grow: 33 !important;
  }
  .flex-lg-100 {
    flex-grow: 100 !important;
  }
}

.align-self-flex-end {
  align-self: flex-end;
}
.align-self-flex-start {
  align-self: flex-start;
}

.flex-kebab-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-kebab-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-kebab-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .trashButton {
    margin: 10px;
    padding: 10px !important;
  }
  .saveButton {
    margin-left: auto;
  }
}

.flex-kebab-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-kebab-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-kebab-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-spread-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-spread-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
}

.flex-spread-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.flex-spread-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-spread-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
}

.flex-spread-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

@media (max-width: $breakpoint-sm) {
  .flex-sm-kebab-left {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  .flex-sm-kebab-right {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  .flex-sm-kebab-row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .flex-sm-kebab-top {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  .flex-sm-kebab-bottom {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  .flex-sm-kebab-column {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .flex-sm-spread-left {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: stretch !important;
  }

  .flex-sm-spread-right {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
  }

  .flex-sm-spread-row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: stretch !important;
  }

  .flex-sm-spread-top {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: stretch !important;
  }

  .flex-sm-spread-bottom {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
  }

  .flex-sm-spread-column {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: stretch !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}

.grid {
  display: grid;
}

.grid-with-gutter,
.grid-with-gutters {
  display: grid;
  // margin-left: -$gutter;
  margin-bottom: -$gutter;

  > div {
    margin-left: 0.5 * $gutter;
    margin-bottom: $gutter;
  }
}

.grid-with-half-gutter,
.grid-with-half-gutters {
  display: grid;
  margin-left: -0.5 * $gutter;
  margin-bottom: -0.5 * $gutter;

  > div {
    margin-left: 0.5 * $gutter;
    margin-bottom: 0.5 * $gutter;
  }
}

.grid-with-padding {
  display: grid;
  margin-left: -$cell-padding;
  margin-bottom: -$cell-padding;

  > div {
    margin-left: $cell-padding;
    margin-bottom: $cell-padding;
  }
}

.grid-with-column-gutters {
  display: grid;
  margin-left: -$gutter;

  > div {
    margin-left: $gutter;
  }
}

.grid-template-single {
  grid-template-columns: 100%;
}
.grid-template-halves {
  grid-template-columns: 50% 50%;
}
.grid-template-thirds {
  grid-template-columns: repeat(3, 1fr);
}
.grid-template-quarters {
  grid-template-columns: 25% 25% 25% 25%;
}
.grid-template-fifths {
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.grid-template-sixths {
  grid-template-columns: 17% 17% 16% 16% 17% 17%;
}
.grid-template-50-25-25 {
  grid-template-columns: 50% 25% 25%;
}
.grid-template-25-50-25 {
  grid-template-columns: 25% 50% 25%;
}
.grid-template-60-20-20 {
  grid-template-columns: 60% 20% 20%;
}
.grid-template-20-60-20 {
  grid-template-columns: 20% 60% 20%;
}
.grid-template-15-70-15 {
  grid-template-columns: 15% 70% 15%;
}
.grid-template-60-40 {
  grid-template-columns: 60% 40%;
}
.grid-template-40-60 {
  grid-template-columns: 40% 60%;
}
.grid-template-66-33 {
  grid-template-columns: 66% 34%;
}
.grid-template-66-34 {
  grid-template-columns: 66% 34%;
}
.grid-template-70-30 {
  grid-template-columns: 70% 30%;
}
.grid-template-30-70 {
  grid-template-columns: 30% 70%;
}
.grid-template-75-25 {
  grid-template-columns: 75% 25%;
}
.grid-template-25-75 {
  grid-template-columns: 25% 75%;
}
.grid-template-80-20 {
  grid-template-columns: 80% 20%;
}
.grid-template-20-80 {
  grid-template-columns: 20% 80%;
}

@media (max-width: $breakpoint-sm) {
  .grid-sm-template-single {
    grid-template-columns: 1fr !important;
    justify-items: center;
  }
  .grid-sm-template-halves {
    grid-template-columns: 50% 50% !important;
  }
  .grid-sm-template-thirds {
    grid-template-columns: 34% 33% 33% !important;
  }
  .grid-sm-template-quarters {
    grid-template-columns: 25% 25% 25% 25% !important;
  }
  .grid-sm-template-fifths {
    grid-template-columns: 20% 20% 20% 20% 20% !important;
  }
  .grid-sm-template-sixths {
    grid-template-columns: 17% 17% 16% 16% 17% 17% !important;
  }
  .grid-sm-template-50-25-25 {
    grid-template-columns: 50% 25% 25% !important;
  }
  .grid-sm-template-25-50-25 {
    grid-template-columns: 25% 50% 25% !important;
  }
  .grid-sm-template-60-40 {
    grid-template-columns: 60% 40% !important;
  }
  .grid-sm-template-40-60 {
    grid-template-columns: 40% 60% !important;
  }
  .grid-sm-template-75-25 {
    grid-template-columns: 75% 25% !important;
  }
}

@media (min-width: $breakpoint-lg) {
  .grid-lg-template-single {
    grid-template-columns: 100% !important;
  }
  .grid-lg-template-halves {
    grid-template-columns: 50% 50% !important;
  }
  .grid-lg-template-thirds {
    grid-template-columns: 34% 33% 33% !important;
  }
  .grid-lg-template-quarters {
    grid-template-columns: 25% 25% 25% 25% !important;
  }
  .grid-lg-template-fifths {
    grid-template-columns: 20% 20% 20% 20% 20% !important;
  }
  .grid-lg-template-sixths {
    grid-template-columns: 17% 17% 16% 16% 17% 17% !important;
  }
  .grid-lg-template-50-25-25 {
    grid-template-columns: 50% 25% 25% !important;
  }
  .grid-lg-template-25-50-25 {
    grid-template-columns: 25% 50% 25% !important;
  }
  .grid-lg-template-60-40 {
    grid-template-columns: 60% 40% !important;
  }
  .grid-lg-template-40-60 {
    grid-template-columns: 40% 60% !important;
  }
  .grid-lg-template-75-25 {
    grid-template-columns: 75% 25% !important;
  }
}

@media (min-width: $breakpoint-xl) {
  .grid-xl-template-single {
    grid-template-columns: 100% !important;
  }
  .grid-xl-template-halves {
    grid-template-columns: 50% 50% !important;
  }
  .grid-xl-template-thirds {
    grid-template-columns: 34% 33% 33% !important;
  }
  .grid-xl-template-quarters {
    grid-template-columns: 25% 25% 25% 25% !important;
  }
  .grid-xl-template-fifths {
    grid-template-columns: 20% 20% 20% 20% 20% !important;
  }
  .grid-xl-template-sixths {
    grid-template-columns: 17% 17% 16% 16% 17% 17% !important;
  }
  .grid-xl-template-50-25-25 {
    grid-template-columns: 50% 25% 25% !important;
  }
  .grid-xl-template-25-50-25 {
    grid-template-columns: 25% 50% 25% !important;
  }
  .grid-xl-template-60-40 {
    grid-template-columns: 60% 40% !important;
  }
  .grid-xl-template-40-60 {
    grid-template-columns: 40% 60% !important;
  }
  .grid-xl-template-75-25 {
    grid-template-columns: 75% 25% !important;
  }
}

//== SHADOWS AND GRADIENTS ========================================================================
.dark-gradient-from-left {
  background-image: linear-gradient(
    to right,
    $translucent-dark-start,
    $translucent-dark-end 60%
  );
}

.dark-gradient-from-right {
  background-image: linear-gradient(
    to left,
    $translucent-dark-start,
    $translucent-dark-end 60%
  );
}

.dark-gradient-from-top {
  background-image: linear-gradient(
    to bottom,
    $translucent-dark-start,
    $translucent-dark-end 60%
  );
}

.dark-gradient-from-bottom {
  background-image: linear-gradient(
    to top,
    $translucent-dark-start,
    $translucent-dark-end 60%
  );
}

.white-gradient-from-bottom {
  background-image: linear-gradient(to top, $white, rgba($white, 0));
}

.glow-dark-light {
  filter: drop-shadow(0 0 3px $gray-darker);
}

//== ANIMATION ====================================================================================

.move-right-on-hover {
  position: relative;
  left: 0;
  transition: left 0.5s;
}

a:hover .move-right-on-hover {
  left: 0.25 * $gutter;
}

//== PDF Render ====================================================================================

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}
