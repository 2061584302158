@import '../../styles/settings';

.header {
  height: 70px;
  background-color: $black;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;

  .logoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 219px;
    min-width: 190px;
    gap: 20px;
    margin: 20px;

    .logo {
      width: 26px;
      height: 26px;
    }
    .wordmark {
      // margin-left: 10px;
      width: 160px;
      min-height: 26px;
      height: auto;
    }
  }

  .headerSeparator {
    margin-left: 0px;
  }

  .headerNavigation {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    padding: 22px 26px;

    & > * {
      max-height: 18px;
    }

    .text {
      color: $kw-colors-sand-base;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 25px;
    }

    .headerRightContainer {
      display: flex;
      gap: 30px;

      .headerButton {
        color: $kw-colors-sand-base;
        font-weight: 400;
        min-height: 25px;
        height: 25px;
        font-size: 14px;
        flex-wrap: nowrap;
        white-space: nowrap;
        padding: 0 !important;
        outline: none !important;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
