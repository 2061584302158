@import '../../styles/_settings';

.propertyListContainer {
  table {
    margin-top: 15px;
    width: 100%;
    color: #999999;

    p {
      margin-bottom: 0;
    }

    tr {
      padding: 10px;
    }

    thead {
      width: 100%;
      height: 44px;
      text-transform: uppercase;
      color: $black;
      border-bottom: 1px solid $black;

      th {
        width: 100%;
      }
      th:not(:first-child) {
        text-align: center;
      }
    }

    tbody {
      width: 100%;

      tr:nth-child(even) {
        background-color: $sand-dark-opaque;
      }

      tr:hover {
        background-color: rgba($kw-colors-sand-dark, 0.35);
      }

      .propertyItem {
        display: flex;
        align-items: center;
        cursor: pointer;

        td {
          width: 100%;
          .propertyNameContainer {
            display: flex;
            align-items: center;

            .propertyNameInfo {
              margin-left: 20px;
              b {
                font-weight: $font-weight-bold;
                color: $black;
              }
            }
          }

          .dropdownMenuIcon {
            cursor: pointer;
          }
        }
        td:not(:first-child) {
          p,
          div {
            text-align: center;
          }
        }
      }
    }
  }
}

.emptyList {
  height: 260px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 3%;
  b {
    font-size: 18px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #222222 !important;
  }
  p {
    color: #4a4a4a;
  }
}

.loader {
  margin-left: 35px;
  svg {
    circle {
      stroke: #fff;
    }
  }
}
