@import '../../styles/_settings';

.listContainer {
  max-height: calc(65vh - 100px);
  overflow-x: auto;
  padding-bottom: 0;

  table {
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      background-color: $whitest;
      height: 50px;

      tr {
        justify-content: space-between;

        th {
          color: $black;
          font-size: $font-size-small;
          font-weight: $font-weight-bold;
          letter-spacing: $letter-spacing-title;
          text-transform: uppercase;
          border-bottom: $black 1px solid;
          border-collapse: separate;
          border-spacing: 0;
          height: 50px;
        }
      }
    }

    tbody {
      background-color: $white;

      .item {
        height: auto;
        border-bottom: 1px $sand-dark-opaque solid;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
        &:nth-child(even) {
          background-color: $sand-dark-opaque;
        }
        &:hover {
          background-color: rgba($kw-colors-sand-dark, 0.4);
        }

        td {
          align-self: center;
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: $black;
        }
      }
    }
  }
}
.stateIcon {
  height: 50px;
  width: 50px;
  text-align: center;

  svg {
    height: 30px;
    width: 30px;
    margin: 10px 0px;
  }
}

.errors {
  text-transform: none;
  white-space: pre-wrap;
}

.red {
  color: #cf5a5a;
}
.green {
  color: #508755;
}
