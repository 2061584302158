@import '../../styles/settings';

.dropdown {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid $gray-lighter;
  border-radius: 4px;
  height: 60px;
  position: relative;

  .dropdownHeaderContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;

    .dropdownHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      justify-content: space-between;
      padding: 15px 20px;

      .headerTitle {
        font-weight: $header-font-weight;
      }

      .headerTitlePlaceholder {
        color: $gray-darker;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        padding-right: 10px;
      }

      .headerTitle,
      svg {
        align-self: center;
      }
    }
  }

  .dropdownMenu {
    width: 100%;
    background-color: white;
    position: absolute;
    top: 100%;
    border-radius: 4px;
    border: 1px solid $gray-lighter;
    list-style-type: none;
    padding: 0;
    box-shadow: 3px 5px 10px 5px $gray-darker;
    overflow: auto;
    max-height: 300px;
    z-index: 2;

    .menuItem {
      height: 60px;
      padding: 15px 20px;
      border-radius: 4px;
      font-weight: $header-font-weight;

      &:hover {
        background: $kw-colors-sand-dark;
      }
    }

    .menuItemSelected {
      background: $kw-colors-sand-base;
    }
  }
}
