@import '../../../../../styles/settings';

.formSection {
  .sectionHeader {
    height: 45px;
    background-color: $kw-colors-sand-dark;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;

    .sectionTitle {
      color: $whitest;
      font-weight: bold;
      font-family: $font-family-sans;
      font-size: $font-size-base;
      line-height: 19px;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      input {
        position: absolute;
        height: 35px;
        width: 12%;
        opacity: 0;
        padding: 0;
        cursor: pointer;
      }
      .questionIcon {
        margin: 0px 5px;
      }
    }
  }
  .containerImages {
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;

    .containerCardDragging {
      display: flex;
      justify-content: center;
      max-width: 145px;
      align-items: center;
    }

    .containerCard {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 135px;
      max-height: 130px;
      margin: 1rem 0px 1rem 0px;
      align-items: center;
    }
    .containerIcons > div {
      left: 50;
      right: 50;
      top: 0;
      bottom: 0;
      height: 100%;
      position: absolute;
    }
    .containerIcons > div button {
      opacity: 0;
      transition-delay: 0s;
    }

    .containerIcons:hover > div button {
      opacity: 0.9;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s;
    }

    .imageInputButtonTrash {
      position: absolute;
      height: 40px;
      left: 79px;
      width: 40px;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .imageInputButtonDrag {
      position: absolute;
      height: 40px;
      left: 40px;
      width: 40px;
      top: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
