@import '../../styles/settings';

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;

  .statsTitle {
    color: $black;
    letter-spacing: $letter-spacing-title;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  .statsValue {
    color: $kw-colors-sand-dark;
  }
}
