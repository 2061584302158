@import '../../styles/_settings';

.createForm {
  max-width: 465px !important;

  button {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 40px !important;
    font-size: 14px !important;
    height: 40px !important;
    svg {
      height: 35px !important;
      width: 35px !important;
    }
  }
  p {
    font-size: 16px !important;
    color: #999999 !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  legend > span {
    -webkit-transform: translate(0, -6px) !important;
    transform: translate(0, -6px) !important;
    padding: 0px 10px !important;
    font-size: 12px !important;
    margin: 0 !important;
    background-color: white !important;
  }

  :not(legend) > span {
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
  }
}

.communityNameContainer {
  display: flex !important;
  flex-direction: row !important;
  color: #999999 !important;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  padding: 10px;

  .communityNameInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    font-family: $font-family-sans;
    font-size: 14px;
    b {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #a8947d;
    }
    p {
      line-height: 16px;
      color: #999999 !important;
      margin-bottom: 0px;
    }
  }
}

.careTypeIconGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 40px;
}
.careTypeIcon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  background: #d7d7d7;
  &.selected {
    background: #a8947d !important;
  }
}

.newCommunityPopupContent {
  display: flex;
  flex-direction: column;
  min-width: 450px;

  .popupSpan {
    color: $gray-dark;
  }

  .padded {
    padding-bottom: 20px;
  }

  .communityNameContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
  }

  .careTypeSelection {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  .otherTypeSelection {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .careTypeGroup {
      display: flex;
      flex-direction: column;
      margin-top: 35px;

      &:first-of-type {
        margin-top: 25px;
      }

      & > *:not(:first-child) {
        padding: 0px 25px;
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}

.autoCompleteResults {
  background: $white;
  z-index: 99999;
  min-width: 473px;
  width: 80%;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-lighter;
  border-radius: 4px;
  position: absolute;

  .suggestedAddress {
    font-size: 14px !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    border-radius: 4px;
    z-index: 3;
    cursor: pointer;

    &:hover {
      background: $sand-dark-opaque;
    }
  }
}

.popupSpan {
  color: $gray-dark;
}
