@import '../../styles/_settings';

$sand-dark-opaque: rgba($kw-colors-sand-dark, 0.2);

.modal {
  font-size: 12px;
  width: auto;
  background: white;
  border: 1px solid $kw-colors-sand-dark;
  border-radius: 4px;
  padding: 35px;
  margin: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  overflow: auto;

  .header {
    width: 100%;
    font-size: 18px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-bottom: 20px;

    .largeTitle {
      width: auto;
      color: $kw-colors-sand-dark;
      margin-right: 20px;
      margin-bottom: 0;
    }

    .title {
      width: auto;
      color: $kw-colors-sand-dark;
      text-transform: uppercase;
      font-family: $font-family-extended;
      font-size: 14px;
      letter-spacing: 0.08em;
      margin-right: 20px;
    }

    .addButton {
      margin-right: auto;
      input {
        display: none;
      }
      button {
        padding: 0 12px;
      }
    }

    .close {
      position: absolute;
      right: 0;
      color: $kw-colors-sand-dark;
      cursor: pointer;
      background: inherit;
      border: none;
      outline: none;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  .content {
    width: 100%;
    flex: 1 1 auto;
    padding: 16px 24px;
    // border-top: 1px solid rgba(0, 0, 0, 0.12);
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    span,
    p {
      font-family: $font-family-sans;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 141.8%;
      word-break: initial;
    }
  }

  .actions {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 35px 0 0 0;
    -webkit-box-pack: end;
    justify-content: center;
    flex: 0 0 auto;
    button {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
}
