@import '../../../styles/settings';

.checkboxesSelection {
  position: relative;
  margin-bottom: 25px;
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
  z-index: 1;

  &[data-isopen='true'] {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    background: $whitest;
    z-index: 2;
  }

  .selectionHeader {
    cursor: pointer;
    width: 100%;
    height: 70px;
    background-color: $sand-dark-opaque;
    border-radius: $border-radius-base;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 45px;

    .title {
      color: $kw-colors-sand-dark;
      font-weight: bold;
      font-family: $font-family-sans;
      font-size: $font-size-base;
      line-height: 19px;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .selectionBody {
    width: 100%;
    height: auto;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    background-color: $white;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    padding: 30px 60px;
    transition: transform 0.4s, min-height 0.4s;

    .collapseSelectionButton {
      margin: 50px auto 0px auto;
      height: 40px;
      width: 50%;
    }

    .instructions {
      font-family: $font-family-sans;
      font-size: $font-size-large;
    }
  }

  .isBodyClosed {
    transition: transform 0.4s, min-height 0.4s, padding 0s 0.4s;
    padding: 0;
    transform: translateY(-50%) scaleY(0);
  }

  .isBodyOpen {
    transform: scaleY(1), translateY(0%);
    position: relative;
    min-height: 300px;
  }

  .selectAllContainer {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $sand-dark-opaque;
    padding-bottom: 20px;
  }

  .sectionContent {
    padding: 20px;

    .checkboxesGrid {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      margin-top: 20px;
      padding: 0 30px;
    }
  }
}
