@import '../../../../styles/settings';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  align-items: center;
  .title {
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    font-family: $font-family-book;
  }
}

.imagesContainer {
  display: flex;
  margin-bottom: 0.5em;

  div {
    height: 120px;
  }

  .imageCard {
    margin: 0 0.5em;
    max-height: 120px;
  }
}

.modal {
  display: flex;
  justify-content: space-between;
}
