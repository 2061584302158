@import '../../../../../styles/popup';

.modelPopupForm {
  width: 80vw !important;
}

.gridContainer {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  border-bottom: solid 1px #222;
  .rightColumn {
    overflow-y: auto;
    padding: 10px 0;
  }
  .leftColumn {
    .containerImageArrows {
      margin: 10px 0;
      height: 55vh;
      width: 100%;
      position: relative;
      @media (max-height: 768px) {
        height: 50vh;
      }
      .containerImage {
        height: 90%;
        position: relative;
        & > div {
          width: 100% !important;
          height: 100% !important;
          margin: 0 !important;
        }
        .image {
          max-height: 100%;
          width: 100%;
        }
      }
      .containerZoomButtons {
        position: absolute;
        right: 15px;
        z-index: 1;
        width: 22px;
        height: 55px;
        .zoomButtonsIn {
          position: absolute;
          background-color: $whitest;
          right: 0;
          top: 0;
          border: solid 1px #222;
          border-radius: 4px 4px 0 0;
          padding: 0 5px;
          cursor: pointer;
          &:hover,
          &:active {
            box-shadow: 0 0 1px 1px $blackest;
          }
          &:focus {
            outline: 0;
          }
          svg {
            height: 10px;
            width: 10px;
          }
        }
        .zoomButtonsOut {
          position: absolute;
          background-color: $whitest;
          right: 0;
          padding: 0 5px;
          top: 26px;
          border: solid 1px #222;
          border-radius: 0 0 4px 4px;
          cursor: pointer;
          &:hover,
          &:active {
            box-shadow: 0 0 1px 1px $blackest;
          }
          &:focus {
            outline: 0;
          }
          svg {
            height: 10px;
            width: 10px;
          }
        }
      }
    }

    .containerArrows {
      display: flex;
      justify-content: center;
      background-color: $whitest;
      z-index: 1;
      .prevImage {
        margin-right: 10px;
      }

      .prevImage,
      .nextImage {
        cursor: pointer;
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
