@import '../../styles/settings';

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .publishedRating,
  .draftRating {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;

    .ratingWrapper {
      width: fit-content;
    }

    &:after {
      content: '';
      grid-row: 2;
      grid-column: 1;
    }

    p {
      margin-bottom: 0;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .overrideReasonNote {
      min-height: 100px;
    }

    .show {
      opacity: 1;
      scale: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .hidden {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;
    }
  }

  hr {
    margin: 12px 0;
  }
}
