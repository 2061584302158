@import '../../styles/_settings';
@import '../../styles/popup';

.assignPropertyConfirmationContent {
  width: 600px;
  height: 400px;
  display: flex;
  flex-direction: column;
  min-width: 450px;
  background-color: $whitest;
  padding: 40px 40px;
  border-radius: 5px;
  justify-content: space-between;

  .organizationOriginWrapper {
    display: flex;
    flex-direction: column;

    .originRadioGroup {
      display: flex;
      flex-direction: row;
      gap: 50px;
    }
  }

  .fieldGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  label {
    align-self: flex-start;
  }

  .bottomButtons {
    display: flex;
    justify-content: space-between;
  }
}
