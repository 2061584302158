@import '../../styles/_settings';

.tagContainer {
  width: 100px;
  background-color: #d9d9d9;
  padding: 10px;

  .tagContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;

    .tagIcon {
      svg {
        width: 15px;
        height: 15px;
      }
    }

    .tagName,
    .suggestedTag {
      text-align: center;
      font-family: $font-family-sans;
      font-weight: 400;
      line-height: 15px;
    }

    .tagName {
      margin-bottom: 0;
      letter-spacing: 1.1px;
      font-size: 12px;
    }

    .suggestedTag {
      font-style: italic;
      font-size: 10px;
    }

    .bottomButtons {
      margin-top: auto;
      display: flex;
      justify-content: space-between;

      & > * {
        margin: 0 5px;
      }

      button {
        border: 1px solid $whitest;
        background-color: $whitest;

        &:active,
        &:focus {
          outline: none;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .approveButton {
        background-color: $green;
        color: $whitest;
      }
      .rejectButton {
        background-color: $red;
        color: $whitest;
      }
    }

    .spinnerLoader {
      position: absolute;
      top: 35%;
    }
  }

  .loading {
    opacity: 0.5;
  }
}

.approved {
  outline: 2px solid $green;
}

.rejected {
  outline: 2px solid $red;
}
