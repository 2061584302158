.statsBar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  justify-content: space-around;
  align-content: center;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  margin-left: -50px;
  margin-right: -50px;
}
