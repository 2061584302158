@import '../../styles/_settings';

.form {
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 20px;
  width: 600px;

  .photoInputContainer {
    display: flex;
    flex-direction: row;

    img {
      max-width: 290px;
      margin: 0 auto;
      display: block;
    }
    label {
      text-transform: uppercase;
      color: $kw-colors-sand-dark;
      cursor: pointer;
      width: fit-content;
      margin: 0 auto;
      display: block;
      min-width: 140px;
      font-size: 12px;
    }

    input {
      display: none;
    }
  }

  .colorPickerContainer {
    display: flex;
    align-items: center;

    .overlayColorPicker {
      padding: 4px 4px;
      height: 30px;
      margin-left: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
      margin-left: auto;
      color: $kw-colors-sand-dark;
      font-size: 12px;
    }
  }
}

.logoImage {
  object-fit: scale-down;
  overflow: hidden;
}

.deleteImageButton {
  position: absolute;
  right: 30px;
  padding: 0 5px;
}

.squaredCheckbox {
  position: relative;
  display: flex;
  flex-direction: row;

  .checkboxInput {
    display: none;
  }

  .labelCheckbox {
    cursor: pointer;
    position: relative;
    width: 23px;
    height: 23px;
    top: 0;
    border-radius: 4px;
    border: 1px solid $gray-lighter;
    background: transparent;
  }

  .labelCheckbox:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 7px;
    background: transparent;
    top: 5px;
    left: 5px;
    border: 3px solid #000000;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  .labelCheckbox:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  input[type='checkbox']:checked + .labelCheckbox {
    background: black;
  }

  input[type='checkbox']:checked + .labelCheckbox:after {
    filter: alpha(opacity=100);
    border-color: white;
    opacity: 1;
  }

  .labelText {
    color: $black;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
  }
}

.bottomButtons {
  margin-top: 20px;
  justify-content: center;
}
