@import '../../styles/_settings';

.propertyListContainer {
  h4 {
    font-family: Maison Neue, serif;
    font-size: 28px;
    font-weight: 400;
    margin-top: 1em;
  }
  table {
    margin-top: 25px;
    width: 100%;
    color: #999999 !important;
    empty-cells: show;

    thead {
      tr {
        th {
          color: $black;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;
      tr {
        td {
          padding: 10px;
          color: $black;
          a {
            color: $black !important;
          }
        }
      }
    }
  }
}

.propertyName {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $black !important;
}

.green {
  color: #006908 !important;
  background-color: #d7f7c2;
  font-weight: $font-weight-bold;
  padding: 1px 6px;
  border-radius: 4px;
}
.grey {
  color: #4f566b !important;
  background-color: #e3e8ee;
  padding: 0 6px;
  border-radius: 4px;
  margin-left: 4px;
}
