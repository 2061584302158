@import '../../styles/_settings';
@import '../../styles/_actionButtons';

.tableContainer {
  width: 100%;
  margin-top: 25px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      border-bottom: 2px solid $black;

      tr {
        padding: 0 10px 10px 10px;
        justify-content: space-between;

        th {
          text-transform: uppercase;
          text-align: left;
          padding: 10px;
        }
      }
    }

    tbody {
      background-color: $white;

      tr {
        height: auto;
        min-height: 93px;
        border-bottom: 1px solid $sand-dark-opaque;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          background-color: $sand-dark-opaque;
        }

        td {
          padding: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}

.hoveredRow {
  background-color: $sand-dark-opaque !important;
  transition: background-color 0.2s ease-in-out;
}


.infoTable {
  width: 100%;
}

.infoTable th,
.infoTable td {
  padding: 10px;
  text-align: left;
}

.tableContent {
  white-space: normal !important;
}

.requesterName {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.requesterEmail {
  text-transform: none;
  font-weight: $font-weight-normal;
}

.requestCallStatus {
  text-transform: uppercase;
  font-weight: $font-weight-light;
  font-size: 0.625rem;
  color: $gray-dark;
}

.communityLink {
  color: $accent-color;
  font-weight: $font-weight-bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.requesterNameCell {
  display: flex;
  flex-direction: column;
}

.communityCell {
  display: flex;
  flex-direction: column;
}

// Expandable Message
.messageContainer {
  width: 100%;
  max-width: 350px;
  white-space: normal;
  overflow: hidden;
  position: relative;
}

.messageText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageContainer.expanded .messageText {
  -webkit-line-clamp: unset;
  white-space: normal;
}

.showMore {
  display: block;
  color: #007bff;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
}

.scheduleAt {
  display: flex;
  flex-direction: column;
}