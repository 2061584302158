@import '../../styles/_settings';

$grid-border: 1px solid $gray-lighter;

.stickyActionButtons {
  position: sticky !important;
  margin-top: 138px;
  margin-bottom: 65px;
  top: 10% !important;
}

.showMore {
  width: fit-content;
  margin: 20px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.communityEditItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  min-height: 70px;
  max-width: 100%;

  &:first-of-type {
    padding-top: 0px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $gray-lighter;
  }

  .actionButtons {
    align-self: flex-start;
    padding-top: 10px;
  }

  .itemInfoContainer {
    display: flex;
    width: 100%;

    img {
      height: 65px;
      width: 65px;
      min-height: 65px;
      min-width: 65px;
      padding: 0;
      margin-right: 15px;
      box-sizing: content-box;
      object-fit: scale-down;
      overflow: hidden;
    }

    .itemInfo {
      width: 100%;
      max-width: 100%;
      margin-right: 10px;

      h4 {
        line-height: 1rem;
      }

      p {
        color: $black;
        letter-spacing: 0.02em;
        margin: 0;
        span {
          color: #a8947d;
        }
      }

      .containerButtons {
        margin-top: 10px;
      }

      .infoGrid {
        display: grid;
        grid-template-columns: 80px 1fr;
        gap: 5px;
        margin-top: 10px;

        & span {
          color: $black;
          letter-spacing: 0.02em;
          font-size: 0.75rem;
          line-height: 0.875rem;
          font-family: Helvetica, $font-family-sans;
          font-weight: 400;
        }
        & span:nth-child(odd):after {
          content: ':';
        }
      }

      .queueChangesContainer {
        margin-top: 20px;

        .queueChangesGrid {
          padding-right: 60px;
          margin-bottom: 20px;

          .sectionTitle {
            display: grid;
            grid-template-columns: repeat(3, minmax(100px, 1fr));
            background-color: #f2e6da;
            position: sticky !important;
            border: 1px solid $grid-border !important;

            span {
              padding: 10px 24px;
              font-weight: 700;
              font-family: $font-family-extended;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.08em;
              text-transform: uppercase;
            }
          }

          .sectionBody {
            border-bottom: $grid-border;
            border-right: $grid-border;

            & > div,
            & > span {
              font-size: 0.75rem;
              letter-spacing: 0.02em;
              font-weight: 400;
            }

            .rowField {
              padding: 8px 24px;
            }

            .valueField {
              display: grid;
              grid-template-columns: repeat(3, minmax(100px, 1fr));

              & > div {
                padding: 8px 24px;
                border-left: $grid-border;
                border-top: $grid-border;
              }

              & > div.emptyValue {
                padding: 0;
                border-left: $grid-border;
                border-top: $grid-border;
              }
            }
          }
        }
      }
    }
  }

  .arrayDiffTitle {
    font-size: 12px;
    font-family: $font-family-extended;
  }

  .added {
    color: #7aac7e !important;
    div {
      color: #7aac7e !important;
    }
    p {
      color: #7aac7e !important;
    }
  }

  .arrayTitle {
    font-family: $font-family-extended;
    font-weight: 600;
    margin-left: -5px !important;
  }

  .removed {
    color: #ad352d !important;
    div {
      color: #ad352d !important;
    }
    p {
      color: #ad352d !important;
    }
  }

  .differenceGrid {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    padding-right: 60px;
    margin-bottom: 20px;
    margin-top: 20px;

    .fieldNameGrid {
      display: flex;
      flex-direction: column;
    }

    p {
      display: inline-flex;
      margin-left: 5px;
      &:after {
        content: ', ';
      }

      &:last-of-type::after {
        content: '';
      }
    }

    & > div,
    & > span {
      font-size: 0.75rem;
      letter-spacing: 0.02em;
      padding: 8px;
      font-weight: 400;
      border-bottom: $grid-border;
      border-left: $grid-border;
      border-right: $grid-border;
    }

    & > div {
      background-color: $kw-colors-sand-dark;
      color: $whitest;
      font-weight: bold;
    }

    & > span {
      color: $black;
      white-space: pre-line;
    }

    & > div {
      border-top: $grid-border;
    }

    & > div:nth-of-type(3n-1),
    & > span:nth-of-type(3n-1) {
      border-left: 0px;
      border-right: 0px;
    }
  }
  .imageDifferenceGrid {
    display: flex;
    padding-right: 60px;
    margin-bottom: 20px;
    margin-top: 20px;

    .dividedSection {
      flex: 1;

      .header {
        width: 100%;
        font-size: 0.75rem;
        letter-spacing: 0.02em;
        padding: 8px;
        font-weight: 400;
        border-bottom: $grid-border;
        border-left: $grid-border;
        border-right: $grid-border;
        background-color: $kw-colors-sand-dark;
        color: $whitest;
        font-weight: bold;
      }
      .imageGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        .detailCard {
          padding: 20px;
          margin: 0;
          height: auto;
          width: auto;
          max-width: 200px;

          div {
            max-height: 100px;
            max-width: 200px;
            overflow: hidden;
          }

          img {
            height: 100px;
            width: auto;
            margin-right: auto;
            margin-left: auto;
          }
        }
        .detailValueName {
          display: block;
          text-align: center;
        }
        .detailValue {
          text-align: center;
        }
      }
    }
  }
}
.noChangesDiv {
  width: fit-content;
  margin: 80px auto 80px auto;
  text-align: center;
  font-family: $font-family-serif;

  .noChangesText {
    color: $kw-colors-sand-dark;
    font-weight: 500;
    font-size: 2rem;
  }
  .noChangesSubText {
    color: $black;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
}
