@import '../../../../styles/_settings';

.autoCompleteResults {
  position: absolute;
  background: $white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-mid;
  border-radius: 4px;
  min-width: 473px;
  width: 43%;

  .suggestedAddress {
    background: $white;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 18px;

    .poiName {
      font-weight: 500;
      letter-spacing: 0.05em;
      display: block;
    }
    .poiAddress {
      color: #757575;
    }

    &:hover {
      background: $sand-dark-opaque;
    }
  }
}
