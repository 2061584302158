@import '../../styles/_settings';

.communitySearchResult {
  align-items: center;
  height: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px $sand-dark-opaque solid;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  color: #999999;

  .communityInfo {
    width: 415px;
    .communityDetails {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      color: #999;
      font-size: 0.765rem;
      font-weight: 500;
    }
  }
  .communityButtonContainer {
    display: flex;
  }

  &:hover {
    background-color: $sand-dark-opaque;
  }
}
