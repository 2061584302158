@import '../../styles/settings';

.editPropertyPage {
  min-width: calc(100vw - 360px);
  p,
  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }

  .navigator {
    text-decoration: underline;
    font-family: $font-family-extended;
    font-size: 14px;
    color: $black;
    line-height: 16px;
    letter-spacing: 1.1px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 12px;
  }

  .subHeader {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;

    .organizationName {
      text-transform: uppercase;
      color: $kw-colors-sand-dark;
      margin-top: 24px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: pre;

      // property name and pencil icon
      .name {
        display: flex;
        align-items: center;

        .propertyNameInput {
          border: none;
          font-size: 52px;
          font-family: $font-family-serif;
          font-weight: 400;
          letter-spacing: 0;
          background-color: transparent;
          min-width: 100px;
          max-width: 75%;
          padding: 0;
          height: 60px;
        }

        .editNameIcon {
          cursor: pointer;
          margin-left: 6px;
        }

        .hidden {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s 0.3s, opacity 0.3s linear;
        }
      }
    }

    // address
    .address {
      font-family: $font-family-sans;
      font-size: 14px;
      line-height: 18.59px;
      color: #999999;
      font-weight: 400;
      margin-top: 4px;
    }
  }

  // Community Profile
  .communityProfileSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .communityProfileBody {
      display: grid;
      grid-template-columns: 30% 15% 15% 20% 15%;
      grid-row-gap: 12px;
      align-items: center;

      .columnHeader {
        text-transform: uppercase;
        color: $kw-colors-sand-dark;
        font-weight: 500;
      }
    }
  }

  // Assessment Link Lists
  .assessmentSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .assessmentsTypeList {
      .assessments {
        display: grid;
        grid-template-columns: 20% 10% 25% 15% 15% 15%;
        padding: 16px 20px;
        align-items: center;

        .linkCount {
          text-decoration: underline;
          cursor: pointer;
        }

        .completedCount,
        .newProspectsCount {
          font-family: $font-family-sans;
          font-weight: 700;
          font-style: italic;
          text-decoration: underline;
          font-size: 14px;
          color: $black;
        }

        .viewReportLink,
        .previewAssessmentLink {
          font-family: $font-family-extended;
          font-weight: 500;
          font-size: 14px;
          color: $blackest;
          text-decoration: underline;
          cursor: pointer;
        }

        &:nth-child(odd) {
          background-color: $white;
        }
        &:nth-child(even) {
          background-color: $sand-dark-opaque;
        }

        div {
          &:not(:first-child) {
            text-align: center;
          }
        }
      }
    }
  }

  .prospectSection {
    display: flex;
    flex-direction: column;

    .viewMoreButton {
      width: 200px;
      margin: 24px auto 0 auto;
    }
  }
}
