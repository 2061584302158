@import '../../../../styles/settings';

.radioWrapper {
  margin-top: 12px;

  .label {
    margin-bottom: 0;
  }
}

.radioGroup {
  display: block;
  grid-template-columns: repeat(5, 1fr);
}
