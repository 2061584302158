@import '../../../styles/settings';

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 350px;
  width: 90%;
  background-color: #f0f0f0;
  //margin-left: auto;
  //margin-right: auto;
  margin: 0 auto 48px auto;

  h3 {
    text-align: center;
    margin-top: 120px;
    z-index: 1;
  }

  hr {
    width: 220px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  button {
    width: fit-content;
    margin-top: 20px;
    z-index: 0;
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 0;
    cursor: pointer;
  }
}

.tabDescription {
  color: #999999;
  line-height: 19px;
  padding-bottom: 45px;
}

.loadingDialogue {
  margin: 200px auto;

  div > svg {
    height: 100px;
    width: 100px;
  }

  h3 {
    text-align: center;
    margin-bottom: 40px;
  }
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 10px;
  margin-top: 10px;
  button {
    min-width: 180px;
  }
}

.error {
  margin-top: 25px;
  height: 300px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 3%;

  b {
    font-size: 18px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #222222 !important;
  }

  p {
    margin-top: 20px;
    font-size: 16px;
    color: #4a4a4a;
  }
  button {
    margin-right: auto;
    margin-left: auto;
    width: 200px;
    height: 60px;
  }
}

.red {
  color: #cf5a5a;
}

.sectionHeader {
  height: 55px;
  background-color: $kw-colors-sand-dark;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .sectionTitle {
    color: $whitest;
    font-weight: bold;
    font-family: $font-family-sans;
    font-size: $font-size-base;
    line-height: 19px;
    letter-spacing: $letter-spacing-title;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.yardiUpload {
  margin: 50px 0;

  .credentialsContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;

    .showCredentialsButton {
      margin-bottom: 24px;
    }
  }
  .credentials {
    display: grid;
    grid-template-columns: 30% 70%;
    width: 350px;
    min-height: 150px;
  }
}
