@import '../../styles/_settings';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
  min-width: 360px;
  max-width: 40vw;

  .imageGrid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: white;
    box-sizing: border-box;

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
    }

    .imageContainer {
      width: 150px;
      height: 150px;
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .buttonContainer {
        position: relative;

        img {
          max-width: 150px;
          max-height: 150px;
          object-fit: contain;
          cursor: pointer;
        }

        .deleteImageButton {
          border-radius: 0 0 0 4px;
          position: absolute;
          top: 0;
          right: 0;
          padding: 4px 8px 0;
          background-color: rgba(255, 255, 255, 0.5);
          border: none;
          cursor: pointer;
        }

        .selected {
          border: 3px solid $kw-colors-sand-dark;
          border-radius: 2px;
          background-color: $kw-colors-sand-dark;
        }
      }
    }
  }
}
