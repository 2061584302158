@import '../../styles/settings';

.editorContent {
  min-height: calc(100vh - 70px);
}

.content {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 70px - 50px);

  & > *:nth-child(2) {
    flex-grow: 1;
  }
}
