@import '../../styles/settings';

.completedAssessmentsListContainer {
  width: 100%;

  .emptyList {
    display: flex;
    flex-direction: column;
    height: 260px;
    background: #f4f4f4;
    align-items: center;
    justify-content: center;

    b {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: $black;
    }
  }

  table {
    width: 100%;
    margin-top: 25px;

    thead {
      border-bottom: 1px solid $black;

      tr {
        th {
          padding: 10px;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;

      .assessmentItem {
        min-height: 100px;
        border-bottom: 1px solid $sand-dark-opaque;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
        &:nth-child(even) {
          background-color: $sand-dark-opaque;
        }
        &:hover {
          background-color: $kw-colors-sand-lighter;
        }

        td {
          padding: 10px;

          .adminButtonsWrapper {
            display: flex;
          }

          .adminButtons {
            display: flex;
            flex-shrink: 1;
            flex-direction: column;
            align-items: center;
            button {
              margin-bottom: 10px;
            }

            .link {
              cursor: pointer;
              color: $secondary-color;
              text-decoration: underline;
            }
          }
        }

        td:first-child,
        td:last-child {
          white-space: pre;
        }
      }
    }
  }
}
