@import '../../styles/settings';

.paginationControls {
  padding: 0.5rem;
  display: flex;
  gap: 10px;
  align-items: center;

  button {
    font-weight: 900;
    font-size: 1rem;
  }
}
