@import '../../../styles/settings';

.communitySelect {
  background-color: $black;
  display: flex;
  padding: 10px 15px 15px 60px;

  button {
    padding: 0 10px;
  }
}
.communitySelectCompressed {
  background-color: $black;
  display: flex;
  padding: 20px 0;
  button {
    padding: 0 10px;
  }
}
