@import '../../styles/settings';

.emptyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  background-color: $gray-lightest;
  margin: 25px 0;
  padding: 20px;

  .message {
    font-size: 1rem;
  }
}
