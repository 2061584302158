.errorHeader {
  margin: 0;
  display: flex;
  grid-template-columns: 50% 50%;
  @media (max-width: 640px) {
    flex-direction: column;
  }

  @media (min-width: 992px) {
    grid-template-columns: 50% 50%;
    overflow: hidden;
  }

  > .errorAlert {
    padding: 0 40px;
    flex-grow: 1;
    div {
      width: fit-content;
      margin: 200px 0 200px auto;
      h1 {
        font-size: 90px;
      }
      @media (max-width: 640px) {
        text-align: center;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 300px;
        margin: 0 0 40px 0;
        padding-top: 50px;
        border-bottom: 2px solid #ac947a;
        border-width: 80%;
      }
    }
  }
  > .errorText {
    max-width: 50%;
    h2 {
      border-left: 2px solid #ac947a;
      padding-left: 50px;
      margin: 200px auto 200px 0;
      max-width: 50%;
      @media (max-width: 640px) {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 300px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        border-left: none;
      }
    }

    @media (max-width: 640px) {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 300px;
    }
  }
}
