@import '../../styles/_settings';

.adminLoginAsUserPopupContent {
  .userInfo {
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
