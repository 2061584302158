@import '../../styles/popup';

.datePicker {
  display: block;
  padding: 10px 0px;

  button {
    &:focus {
      outline: none !important;
    }
  }
}

.timePicker {
  display: block;
  padding: 5px 0px;
  button {
    &:focus {
      outline: none !important;
    }
    border: none;
  }

  .timeZoneText {
    margin-top: 5px;
    color: #999999;
  }
}

.duration {
  padding: 0px 0px;
  width: 242px;
}
