@import '../../../../../styles/popup';

.floorplanPhotoUpload {
  height: 210px !important;

  h3 {
    margin-top: 0 !important;
  }
}

.floorplanUploadHeader {
  margin-top: 18px;
}

.floorplanFormBody {
  overflow-y: scroll;
  max-height: 80vh;
}
