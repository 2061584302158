@import '../../styles/settings';

.authPage {
  height: 100vh;
  display: flex;
  background-image: url('../../assets/background-texture-1.jpg');
  background-size: cover;

  .authFormWrapper {
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 100%;
    padding: 50px;

    .authTitle {
      color: $whitest;
      font-size: 3.75rem;
      margin-bottom: 40px;
      margin: auto;
    }
    .authLogo {
      height: 50px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .authSubTitle {
      font-size: 1.75rem;
      margin-left: auto;
      margin-right: auto;
    }

    .authForm {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 100%;
      max-width: 600px;
      margin-top: 25px;
      gap: 20px;

      .columnsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 15px;

        input {
          width: 100%;
        }
      }

      .profileImageContainer {
        label {
          color: $whitest;
          cursor: pointer;
        }

        input {
          display: none;
        }
      }

      .errorsContainer {
        color: $red;
        text-shadow: 0 0 1px #000000;
        background: #565656;
        border: 1px solid #000000;
        padding: 20px;
        border-radius: 4px;
        font-weight: 500;
      }

      .authButton {
        width: 100%;
        height: 60px;
      }

      input {
        height: 60px;
      }
    }

    .formFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 600px;
      width: 100%;
      margin: auto;

      .normalWhiteLabel {
        color: $whitest;
        text-transform: none;
        font-weight: normal;
      }

      .rememberMe {
        display: flex;
        flex-direction: row;
        gap: 15px;
      }

      .signUpSuggestion {
        display: flex;
        align-items: center;
      }
    }
  }
}

.successDiv {
  background: $white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 200px;
  padding: 20px;
  border-radius: 4px;
}
