@import '../../styles/_settings';

.organizationListContainer {
  display: grid;
  gap: 30px 50px;

  .organizationItem {
    position: relative;
    background-color: $gray-lightest;
    min-height: 130px;
    word-break: normal;
    display: flex;

    .logoImage {
      padding: 10px;
      object-fit: scale-down;
      overflow: hidden;
      width: 144px;
      height: 129px;
    }

    .organizationItemText {
      flex: 1;

      .orgLinks {
        display: flex;

        :first-child {
          padding-left: 0px;
          border-left: none;
        }

        :last-child {
          padding-right: 0px;
        }

        p {
          cursor: pointer;
          text-transform: uppercase;
          color: #a8947d;
          padding-right: 10px;
          padding-left: 10px;
          border-left: 1px solid #a8947d;
        }
        a {
          padding-right: 10px;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        color: #222;
        margin-bottom: 0px;
        margin-right: 20px;
        .betaInviteCode {
          display: flex;
          p {
            font-weight: 500;
            margin-bottom: 0px;
            margin-right: 15px;
          }
          span {
            cursor: pointer;
          }
        }
        b {
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }

    .editLink {
      position: absolute;
      top: 17px;
      right: 25px;
      cursor: pointer;
    }

    h4 {
      margin-top: 10px;
    }
  }
}
