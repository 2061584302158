@import '../../../styles/settings';

.container {
  margin-top: 50px;
  max-width: 800px;

  h2 {
    font-weight: bold;
    font-size: $font-size-larger;
  }

  .reportControls {
    margin-bottom: 50px;
    display: flex;
    .assessmentTypeSelect {
      margin-left: 20px;
    }
  }

  .question {
    margin-bottom: 30px;

    h4 {
      font-size: 18px;
    }

    p {
      margin-bottom: 0; // remove default margin from all p elements
    }

    .average {
      // position inline with label
      text-align: right;
      transform: translateX(-58%);
      padding-right: 10px;
    }

    .legendContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
      max-width: 65%;
      width: 100%;
      margin-left: auto;
      margin-right: 16%;

      .legend {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .colorIndicator {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          margin-right: 5px;
        }

        p {
          font-size: 11px;
          font-weight: 500;
        }
      }
    }

    @media (max-width: 1000px) {
      .legendContainer {
        max-width: 80% !important;
      }
    }
  }
}

.answer {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .label {
    width: 42%;
    padding-right: 10px;
    text-align: right;
  }

  .barContainer {
    height: 20px;
    width: 40%;

    .bar {
      height: 100%;
      background-color: $kw-colors-sand-dark;
    }
  }

  .multiBarContainer {
    height: 20px;
    width: 40%;
    display: flex;
    align-items: center;

    .multiBar {
      height: 100%;
    }
  }

  .count {
    width: 18%;
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
}
