@import '../../styles/_settings';

.moderationQueueActionButtons {
  display: flex;
  flex-direction: column;
  width: 120px;
  min-width: 120px;
  align-items: center;

  .actionStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    border-radius: 4px;
    color: $whitest;
    width: 100%;
    text-align: center;
    font-size: 0.625rem;
    line-height: 0.6875rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-family: Helvetica, $font-family-sans;
    font-weight: 400;
  }

  .pending {
    background-color: $kw-colors-sand-dark;
    border-radius: 4px 4px 0 0;
  }

  .accepted {
    background-color: $green;
  }

  .rejected {
    background-color: $red;
  }

  .actionButtonsContainer {
    width: 100%;
    border: 1px solid $kw-colors-sand-dark;

    .actionButton {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      color: $kw-colors-sand-dark;
      cursor: pointer;

      &:not(:last-of-type) {
        border-bottom: 1px solid $kw-colors-sand-dark;
        border-radius: 0;
      }
    }
  }
}
