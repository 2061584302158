@import '../../styles/_settings';

.surveyListContainer {
  table {
    margin-top: 25px;
    width: 100%;

    thead {
      border-bottom: $black 1px solid;
      tr {
        padding: 0 10px 10px 10px;
        justify-content: space-between;

        th {
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;

      .surveyItem {
        height: auto;
        min-height: 93px;
        border-bottom: 1px $sand-dark-opaque solid;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          background-color: $sand-dark-opaque;
        }
      }
      .expandableInfoOpen {
        display: flex;
        height: 105px;
        padding: 25px;
        background-color: $sand-dark-opaque;
        align-items: center;
      }

      .expandableInfoClose {
        display: none;
      }
    }
  }
}

.userName {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.userDetails {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  color: $gray-dark;
}

.inlineButton {
  max-width: 180px;
  font-size: 0.625rem;
  white-space: normal !important;
  line-height: normal !important;
}

.tableContent {
  white-space: normal !important;
}

.tableButtons {
  white-space: normal !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  > button {
    width: 180px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.userEmail {
  @extend .userName;
  text-transform: none;
  font-weight: $font-weight-normal;
  font-size: inherit;
}

.emailPending {
  @extend .userDetails;
  text-transform: uppercase;
  font-weight: $font-weight-light;
  font-size: 0.625rem;
  color: $gray-dark;
}
