@import '../../../../styles/settings';

.squaredCheckbox {
  // position: relative;
  display: flex;
  flex-direction: row;

  .checkboxInput {
    display: none;
  }

  .labelCheckbox {
    cursor: pointer;
    position: relative;
    width: 23px;
    height: 23px;
    top: 0;
    border-radius: 4px;
    border: 1px solid $gray-lighter;
    background: transparent;
    padding-right: 20px;
  }

  .labelCheckbox:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 7px;
    background: transparent;
    top: 5px;
    left: 5px;
    border: 3px solid #000000;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  .labelCheckbox:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  input[type='checkbox']:checked + .labelCheckbox {
    background: black;
  }

  input[type='checkbox']:checked + .labelCheckbox:after {
    filter: alpha(opacity=100);
    border-color: white;
    opacity: 1;
  }

  .labelText {
    color: $black;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
  }
}
