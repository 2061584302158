@import '../../../../styles/_settings';

.formSection {
  .sectionHeader {
    height: 55px;
    background-color: $kw-colors-sand-dark;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;

    .sectionTitle {
      color: $whitest;
      font-weight: bold;
      font-family: $font-family-sans;
      font-size: $font-size-base;
      line-height: 19px;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .sectionTitleButton {
      color: $kw-colors-sand-dark;
      font-weight: bold;
      font-family: $font-family-sans;
      font-size: $font-size-base;
      line-height: 19px;
      letter-spacing: $letter-spacing-title;
      text-transform: uppercase;
      display: inline !important;
      justify-content: flex-end;
    }
  }
}
