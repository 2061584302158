@import '../../styles/_consumerAdmin';

.infoUnit {
  font-family: Helvetica, $font-family-sans;
  padding-top: 15px;
  font-size: 16px;
  margin-bottom: 0px;
  color: #a8947d;
  span {
    color: #999999;
  }
}
