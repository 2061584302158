@import '../../../styles/settings';

.singleInput {
  padding: 12px;

  .currencyInput {
    input {
      padding-left: 0;
      padding-right: 9px;
    }
  }
}
