@import '../../../styles/settings';

.onboardingVideoPopup {
  width: 60vw;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  h3 {
    margin-bottom: 24px;
  }

  .videoSection {
    width: 100%;
    height: 80%;
    margin-bottom: 24px;
  }

  .bottomButtons {
    display: flex;
    flex-direction: row-reverse;

    button {
      width: 175px;
    }
  }
}
