@import '../../styles/settings';

// selected link style
.selected {
  background-color: $kw-colors-sand-dark;
}

// text input
.singleTextSection {
  margin-bottom: 24px;

  .textInput {
    width: 100%;
    border: 1px solid #999999;
    font-size: 14px;
    height: 50px;
    border-radius: 4px;
    margin-bottom: 24px;
  }
}

// text area
.textareaInput {
  margin-bottom: 24px;

  textarea {
    resize: none;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
  }
}

input[type='color'] {
  height: 35px;
}

// buttons
.buttons {
  display: inline-flex;
  border: 1px solid $black;
  border-radius: 5px;

  .styleButton {
    min-width: 140px;
    padding: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;

    &:hover {
      background-color: $kw-colors-sand-darker;
      cursor: pointer;
    }

    &:not(:last-child) {
      border-right: 1px solid $black;
    }
  }
}

.bannerPage {
  display: flex;
  flex-direction: column;

  h3,
  h4,
  p {
    font-family: $font-family-sans;
  }

  h4 {
    font-weight: 500;
  }

  // Banner
  .bannerSection {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: $white;
    }
  }

  // Editor / Left side
  .editorSection {
    width: 100%;
    display: flex;
    flex-direction: column;

    .editorHeader {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 64px;
      padding: 10px 60px;
      background-color: $black;

      a.backwardLink,
      a.backwardLink:link,
      a.backwardLink:visited {
        color: $white;
      }

      .backwardArrow {
        height: 28px;
        width: 28px;
        color: $whitest;
      }

      .bannerName {
        margin: auto;
        width: fit-content;
        &:hover,
        &:focus,
        &:focus-within {
          background-color: rgba($kw-colors-sand-dark, 0.3);
          border: 1px solid $kw-colors-sand-dark;
        }

        .bannerNameInput {
          border: none;
          font-size: 28px;
          color: white;
          background-color: transparent;
          min-width: 100px;
          width: fit-content;
          max-width: 80%;
        }

        .pencilIcon {
        }

        .hidden {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s 0.3s, opacity 0.3s linear;
        }
      }
    }

    .editorBody {
      display: grid;
      grid-template-columns: 35% 65%;
      margin-top: 24px;
      padding: 30px 60px;

      .editor {
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid $black;

        .linkStyleSelector {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          margin-bottom: 24px;

          h4 {
            margin-bottom: 12px;
            margin-right: 20px;
          }

          .linkStyle {
            //border: 1px solid $black;
            //border-radius: 5px;
            //padding: 10px;
            //text-align: center;
            font-size: 24px;
            font-weight: 700;
          }
        }

        .backgroundImageUploader {
          margin-bottom: 24px;

          .backgroundImageContainer {
            width: 100%;
            height: 200px;
            position: relative;
            overflow: hidden;
            margin-bottom: 24px;

            img {
              width: 100%;
              height: 200px;
              object-fit: cover;
              object-position: center;
            }

            button {
              width: 120px;
              height: 50px;
              position: absolute;
              top: calc(50% - 25px);
              left: calc(50% - 75px);
            }
          }

          .colorPicker {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            margin-left: 12px;

            label {
              margin-left: 30px;
              font-size: 16px;
              font-weight: 700;
            }
          }
        }

        .buttonStyle {
          padding: 14px;
          background-color: #d9d9d9;
          margin-bottom: 24px;

          .buttonText {
            label {
              font-size: 16px;
              font-weight: 700;
            }
          }

          .buttonColorPicker {
            margin-bottom: 12px;
            margin-left: 12px;
            display: flex;
            align-items: center;

            label {
              font-size: 16px;
              font-weight: 700;
              margin-left: 30px;
            }
          }
        }
      }

      .previewSection {
        padding-left: 50px;

        .previewHeader {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          h4 {
            margin-bottom: 0;
            margin-right: 12px;
          }
        }

        .previewContent {
          display: flex;
          flex-direction: column;
          padding-bottom: 48px;
          border-bottom: 1px solid $black;

          .title {
            display: flex;
            align-items: center;
            padding: 20px 0;
            align-self: center;

            h5 {
              font-family: $font-family-sans;
              font-size: 16px;
              margin-bottom: 0;
              margin-right: 16px;
              font-weight: 700;
            }

            .buttons {
            }

            .columnButton {
              padding: 4px;
              min-width: 100px;
              font-size: 14px;
            }
          }

          .contentsPanel {
            background-color: #d9d9d9;
          }

          .tabletPanel {
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;

            .oneColumn {
              width: 768px;
            }
          }

          .mobilePanel {
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            .oneColumn {
              width: 400px;
            }
          }

          // preview section - for all layouts
          .oneColumn,
          .twoColumn {
            padding: 30px;
            height: 600px;

            display: grid;
            grid-gap: 22px;

            div {
              border-radius: 4px;
            }
          }

          .topPanel,
          .rightPanel {
            padding: 12px;
          }

          // preview section - for one column layout
          .oneColumn {
            grid-template-rows: 1fr 1.2fr;
            .topPanel {
              grid-area: 1 / 1 / 1 / 1;
              background-color: $white;
            }
            .rightPanel {
              display: none;
            }
            .bottomPanel {
              background-color: $white;
            }
          }

          // preview section - two columns layout(default)
          .twoColumn {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr 1.2fr;

            .topPanel {
              grid-area: 1 / 1 / 2 / 2;
              background-color: $white;
            }
            .mainPanel {
              grid-area: 2 / 1 / 3 / 2;
            }
            .rightPanel {
              grid-area: 1 / 2 / 3 / 3;
              background-color: $white;
            }
          }

          // shared styling setting
          .mainPanel {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #fff;
            text-align: left;

            .header {
              font-size: 16px;
              margin-bottom: 24px;
            }

            .subheader {
              font-size: 14px;
              line-height: 14.52px;
              margin-bottom: 18px;
            }

            .button {
              margin-bottom: 24px;
            }

            .kithwardLogo {
              display: flex;
              p {
                margin-right: 12px;
              }
            }
          }

          .embeddedLinkPanel {
            align-items: normal;
            justify-content: normal;
            padding: 12px;
          }
        }

        .snippetSection {
          margin-top: 28px;
          .bottomButtons {
            display: flex;
          }
        }
      }
    }
  }
}
