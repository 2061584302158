// scss-lint:disable ImportantRule, PropertySortOrder, IdSelector, ZeroUnit, LeadingZero,
// scss-lint:disable DeclarationOrder, ImportPath, SingleLinePerSelector, SelectorFormat, QualifyingElement
// scss-lint:disable SpaceBeforeBrace, SpaceAfterPropertyColon, SelectorDepth, MergeableSelector, NestingDepth

@import './settings';

i.glyphicon.glyphicon-plus:after {
  content: '+';
}

i.glyphicon.glyphicon-remove:after {
  content: '-';
}

.popup-overlay {
  background: rgb(0, 0, 0, 0.5);
}

.data-section {
  .data-label {
    font-weight: bold;
    margin-bottom: 0.5 * $gutter;
  }

  .data-value {
    margin-top: -0.5 * $gutter;
    margin-bottom: 0.5 * $gutter;
  }

  .data-type-text {
    font-size: 16px;
    line-height: 1.88;
    white-space: pre-wrap;
  }

  .data-type-flag,
  .data-type-amenity {
    white-space: nowrap;
  }
}

.page-community-details {
  .main-image,
  .map {
    height: 40vh;
  }

  @media (min-width: $breakpoint-lg) {
    .main-image,
    .map {
      height: 50vh;
    }
  }

  .main-image {
    margin-bottom: $gutter;
    position: relative;
    width: 100%;

    img {
      // max-width: 100vw;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .caption {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: $white;
      padding: 0 $gutter;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 80%;
      font-weight: 500;
      letter-spacing: 1.1px;
      line-height: 48px;

      &:hover {
        background-color: $background-color;
        color: $color;
        transition: 0.2s;
      }
    }
  }

  h2 {
    margin-bottom: 0;
    padding-top: 35px; //to offset sticky nav bar
  }

  h3 {
    margin-top: $gutter;
  }
  @media (max-width: $breakpoint-sm) {
    h3 {
      text-align: center;
    }
  }

  .section-desc {
    font-size: 16px;
    margin-top: 0.5 * $gutter;
    margin-bottom: 0;
  }
}

.communities-dashboard {
  padding-bottom: 70px;

  .header {
    .title {
      margin: 0;
    }
  }

  .separator {
    border-left: 1px $gray-mid solid;
    padding-right: 25px;
    margin-left: 25px;
    height: 25px;
    &__user {
      border-left: 1px $gray-mid solid;
      padding-right: 25px;
      height: 25px;
      margin-left: 0 !important;
    }
  }

  .search-bar-container {
    margin-top: 20px;
    h3 {
      letter-spacing: $letter-spacing-title;
      font-family: $font-family-sans;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .search-bar {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 1px solid $kw-colors-sand-dark;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 5px 15px;
      align-items: center;
      justify-content: space-between;
      .search-bar-dropdown {
        border: none;
        height: unset;
        flex-grow: 1;
        padding: 5px 5px;
      }
      input {
        border: none;
        height: unset;
        flex-grow: 1;
        padding: 5px 5px;

        &:focus {
          outline: none;
        }
      }

      button.search-button {
        height: 30px;
        align-self: center;
        line-height: inherit;
        padding: 0 10px;
        min-width: 80px;
        max-width: 105px;
        flex-grow: 1;
      }
    }
  }

  .communities-container {
    .admin-communities {
      margin-top: 30px;
    }
  }
}

.community-details-grid {
  grid-template-columns: 1fr 384px;
}

.community {
  margin-top: 27px;

  &__title {
    font-size: 60px;
    letter-spacing: -1.5px;
    @media (max-width: $breakpoint-sm) {
      font-size: 40px;
      line-height: 1.13;
    }
  }
}

.community-data {
  &__title {
    color: $kw-colors-sand-dark;
    font-family: $font-family-sans;
    font-size: 11px;
    letter-spacing: 1.1px;
    line-height: 15px;
    text-transform: uppercase;
  }
}

.community-address {
  color: $kw-colors-sand-dark;
  font-family: $font-family-sans;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}
.button-save,
.button-share {
  background-color: rgba(#ccc, 0.6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: white;
  outline: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 90px;
  letter-spacing: 1.1px;
  border-radius: 3px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  font-size: 11.2px;
  font-weight: 500;
  cursor: pointer;
}
.button-save {
  right: 130px;
}
.button-save svg,
.button-share svg {
  margin-right: 5px;
  height: 17px;
  width: 17px !important;
  transform: translateY(3px);
  color: white;
}
.button-save.selected svg {
  color: #cc0000;
}

.button-icon-save,
.button-icon-share {
  background-color: #fff;
  color: #222;
  border-radius: 3px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
}
.button-icon-save {
  right: 56px;
}
.button-icon-save svg,
.button-icon-share svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  color: #666;
}

.button-icon-save.selected svg {
  color: #cc0000;
}

.main-image__bottom-buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;

  .button {
    background: rgba(#ccc, 0.6);
    border: none;
    color: $white;
    outline: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    margin-left: 20px;
  }
}

.amenities-toggler {
  background-color: $whitest;
  border-bottom: 1px solid $gray-lighter;
  display: flex;
  margin-top: 60px;
  position: sticky;
  padding: 10px 0;
  top: 75px;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: $breakpoint-md) {
    display: none;
  }

  div {
    color: #a1a1a1;
    display: block; //for the anchor links to work on safari
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    text-transform: uppercase;

    &.active {
      border-bottom: 1px solid $color;
      color: $color;
    }
  }
}

.admin-buttons {
  float: right;
}

.page-community-admin {
  background-color: $gray-lighter;

  h3 {
    margin-top: $gutter;
    font-size: $font-size-large;
  }

  .map {
    height: 280px !important;
  }

  .admin-header {
    margin: 0;
    padding-top: $gutter;
    background-color: $gray-lighter;
    margin-top: 0;
    z-index: $zindex-header + 1;

    .nav-tabs .nav-link,
    .nav-tabs .nav-link.active {
      border-bottom: 0;
      cursor: pointer;
    }
  }

  .data-section-edit {
    border-bottom: 2px solid $gray-dark;
    padding-bottom: $gutter;
    margin-bottom: $gutter;
  }

  .admin-image-card {
    margin-bottom: $gutter;
  }

  .admin-image-wrapper {
    position: relative;
    display: inline-block;

    .admin-image-action {
      user-select: none;
      cursor: pointer;
      font-size: 140%;

      &,
      &:href,
      &:active,
      &:hover,
      &:visited {
        text-decoration: none;
        color: $black;
      }

      position: absolute;
      background-color: $white;
      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      &.bottom-left {
        bottom: 2px;
        left: 2px;
      }

      &.top-right {
        top: 2px;
        right: 2px;
      }

      svg {
        color: $black;
      }

      svg.danger {
        color: $danger-color;
      }
    }
  }

  .admin-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: center;
    border: 1px solid $gray-mid;
    padding: 5px;
    background-color: $white;
  }

  .deleted-image .admin-image {
    border: 2px solid $danger-color;
    padding: 4px;
  }

  .new-image .admin-image {
    border: 2px solid $info-color;
    padding: 4px;
  }

  .new-image.deleted-image .admin-image {
    border: 2px solid $danger-color;
    padding: 4px;
  }

  .deleted-listing {
    opacity: 0.4;

    h2 {
      color: $danger-color;
    }
  }

  .deleted-poi {
    opacity: 0.4;
    color: $danger-color;
  }

  .draft-listing {
    h2 {
      color: $warning-color;
    }
  }

  .hidden-listing {
    opacity: 0.5;

    h2 {
      color: $gray-dark;
    }
  }

  input[type='radio'] + label {
    font-weight: $font-weight-normal !important;
  }

  input[type='radio']:checked + label {
    font-weight: $font-weight-bold !important;
  }

  .thumbnail-image a {
    display: block;
    vertical-align: center;
    padding: 0.3rem;
    margin: 0 0.5 * $gutter 0.5 * $gutter 0;
    background-color: $white;
    box-shadow: 0 1px 2px 0 $gray-darker;

    img {
      display: inline-block;
      width: 100%;
      height: auto;

      object-fit: contain;
    }
  }
}

.thumbnail img {
  height: 100%;
  object-fit: contain;
}

.labeled-form-converted-to-list {
  .data-attr {
    align-items: center;
    display: inline-flex;
    font-size: $font-size-smaller;
    font-family: $font-family-book;
    padding: 0 10px;
    margin-right: $cell-padding;
    // border-right: 1px solid $black;
    // margin-bottom: 0.5 * $gutter;
    text-transform: uppercase;
    &--button {
      padding: 0 $gutter;
      height: 35px;
      margin-right: 10px;
      border: 1px solid $gray-lighter;
      border-radius: 3px;
      letter-spacing: 1px;
      font-family: $font-family-sans;
      font-weight: $font-weight-bold;
    }
  }

  .data-attr:last-child {
    // padding-right: 0;
    margin-right: 0;
    // border-right: 0;
  }

  .data-label {
    display: none;
  }
  .data-value {
    margin-top: 0;
    margin-bottom: 0;
  }
  &.bold {
    font-weight: $font-weight-bold;

    .data-attr {
      // border-right: 2px solid $black;
    }

    .data-attr:last-child {
      // border-right: 0;
    }
  }
}

.community-listing-draft {
  opacity: 0.4;
}

.community-listing-hidden {
  opacity: 0.2;
}
.community-listing-unavailable {
  opacity: 0.5;
}

.group-floorplans {
  grid-column: 1 / 4;
}

.group-unit {
  margin-bottom: 10px;
  .data-attr {
    border-right: 1px solid $kw-colors-sand-dark;
    color: $kw-colors-sand-dark;
    font-size: 14px;
    text-transform: initial;
    font-weight: $font-weight-normal;
    line-height: 1.5;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      border: none;
    }
  }
}

.community-listings {
  .group-features {
    .data-attr {
      border: none;
      color: #757575;
      font-size: 12px;
      line-height: 1.67;
      padding: 0;
      text-transform: lowercase;

      &:after {
        content: ', ';
      }

      &:last-of-type::after {
        content: '';
      }
    }

    &:before {
      content: 'Amenities: ';
      color: $black;
      margin-right: $cell-padding;
      font-size: 12px;
      font-family: $font-family-book;
    }

    .data-section-group-entries {
      display: inline;
    }
  }
}

.contact-form {
  h2 {
    font-size: 35px;
    letter-spacing: -0.9px;
    padding-top: 0;
  }

  &__input {
    height: 45px !important;
  }

  .checkbox__label {
    font-weight: 300;
    line-height: 1.7;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
}

.kw-listing-modal {
  background: $whitest;
  color: $black;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  overflow: auto;

  &__header {
    margin-bottom: 50px;
  }

  table {
    width: 100%;
    margin-bottom: 50px;
    text-align: left;
    .btn {
      display: inline-block;
    }
  }

  th {
    color: $kw-colors-sand-dark;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  td {
    padding: 20px 10px 20px 0;
    font-size: 14px;
    b {
      display: none;
    }
  }
  .cost {
    width: 195px;
    text-align: center;
    padding-right: 0;
    .btn {
      text-align: center;
      width: 175px;
      padding: 0;
    }
  }
  .tour {
    width: 160px;
    text-align: center;
    .btn {
      text-align: center;
      width: 140px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    & {
      padding: 30px;
    }
    .cost {
      width: 165px;
      .btn {
        width: 145px;
      }
    }
    .tour {
      width: 140px;
      padding-right: 0;
      .btn {
        width: 130px;
      }
    }
  }
  @media (max-width: 830px) {
    table,
    tr,
    td,
    tbody {
      display: block;
      padding: 0;
      width: 100%;
    }
    tr.header {
      display: none;
    }
    table,
    tr {
      max-width: 480px;
      margin: 0 auto;
    }
    td {
      display: flex;
      flex-direction: row;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 20px;
      border-bottom: 1px solid #ac947a;
      margin-top: 20px;
    }
    td > b {
      text-align: left;
      color: #ac947a;
      display: block;
      width: 120px;
    }
    td > span {
      text-align: right;
      display: block;
      flex: 1;
    }
    td.cost,
    td.tour {
      display: block;
      width: 50%;
    }
  }
  @media (max-width: 420px) {
    td.cost {
      padding-right: 10px;
    }
    td.tour {
      padding-left: 10px;
    }
    td.cost .btn,
    td.tour .btn {
      display: inline-block;
      padding: 0 15px;
      width: 100%;
    }
    td.cost .btn span,
    td.tour .btn span {
      display: none;
    }
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -115px;
  visibility: hidden;
}
.criteria-buttons-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.criteria-apply-button {
  display: inline-block;
  min-height: 10px;
  line-height: 10px;
  padding: 7px;
  font-size: 50%;
  margin-top: 5px;
  background-color: $accent-color;
  border-color: #e4e4e4;
}
.criteria-apply-button.clear {
  background-color: #fff;
  color: #222;
}
.map .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #fff;
}
.map .mapboxgl-popup-content {
  background-color: #fff;
  color: #ac947a;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-family: 'Maison Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
.map .mapboxgl-popup-content .poi {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  max-width: 250px;
}
.map .mapboxgl-popup-content .category {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0;
  color: #222;
}
.map .mapboxgl-popup-content .title {
}
.map .mapboxgl-popup-content .distance {
  font-weight: bold;
  text-align: right;
}
.map .mapboxgl-popup-content .mapboxgl-popup-close-button {
  color: #222;
  font-size: 20px;
  line-height: 38px;
  padding: 0 14px;
}
.map .mapboxgl-marker.map-marker svg {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.map .map-marker div {
  position: relative;
}
.map .map-marker div:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: transparent;
  transform: translate(-50%, -50%);
  content: '';
  display: block;
}

.no-wrap-text-button {
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
