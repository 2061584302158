@import '../../../styles/settings';

.adminNotesContainer {
  display: flex;
  flex-direction: column;

  .adminTitleContainer {
    display: flex;
    //svg {
    //  margin-top: 3px;
    //}
    align-items: center;
  }
}
.addNote {
  font-family: $font-family-sans;
  font-size: 11px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
  width: 100px;
  margin-bottom: 0;
  cursor: pointer;
  align-items: center;
}
.titleNote {
  font-family: $font-family-sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: none;
  margin-left: 10px !important;
  margin-bottom: 0;
}
.dateNote {
  font-family: $font-family-sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
}
.messageNote {
  font-family: $font-family-sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: none;
}

.postNotePopup {
  background-color: #fff;
  height: 700px;
  width: 900px;
  position: relative;
  padding: 60px;
  @media (max-height: $breakpoint-md) {
    height: 560px;
    padding: 48px;
  }

  .title {
    font-family: $font-family-serif;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    font-size: 32px;
  }
  .closeIcon {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
    svg {
      height: 20px;
      width: 28px;
    }
  }
  textarea {
    width: 100%;
    margin-top: 15px;
    height: 54px;
  }
  .containerPostMessage {
    display: flex;
    margin: 10px 0 24px;

    .containerButtonPost {
      display: flex;
      padding-left: 18px;
    }
  }
  .containerPrincipalHistory {
    max-height: 400px;
    overflow: auto;
    padding-right: 48px;
    @media (max-height: $breakpoint-md) {
      max-height: 256px;
    }
    .containerHistory {
      margin: 24px 0;
      display: flex;
      .avatarContainer {
        font-size: 11px !important;
      }
    }
  }
}
