@import '../../styles/_settings';
@import '../../styles/popup';

$sand-dark-opaque: rgba($kw-colors-sand-dark, 0.2);

.communityFormContainer {
  position: relative;
  overflow-y: hidden;
  min-height: calc(100vh - 50px);

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: 2;
  }

  .stickyHeader {
    position: fixed;
    width: 100%;
    z-index: 2;
    background: #fff;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
    background-color: $black;
    align-items: center;

    .titleSection {
      display: flex;

      .arrow {
        display: flex;
        align-items: center;
        cursor: pointer;

        .arrowLoader {
          font-size: 25px;
          margin-right: 9px;

          svg {
            height: 38px;
            width: 38px;
          }
        }

        .backwardArrow {
          height: 28px;
          width: 22px;
          color: $whitest;
          margin-right: 25px;
        }
      }

      .titleLoader {
        svg {
          width: 38px;
          height: 38px;
        }
      }
      .titleAndPercent {
        display: flex;
        align-items: center;
        .title {
          color: $white;
          margin-bottom: 0;
        }
      }
    }
  }

  .communitySelectLoader {
    background-color: $black;
    padding: 12px 15px 12px 60px;

    svg {
      width: 38px;
      height: 36px;
    }
  }

  .communitySelect {
    display: flex;
  }

  .tabContent {
    padding: 50px 60px 0;
  }

  .footer {
    padding: 0px 75px 60px 60px;
    .footerContent {
      .footerButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 40px;

        .groupedFooterButtons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          button {
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.submitForApprovalButtons {
  display: flex;
  align-items: center;
  padding-right: 45px;
}

.popupSpan {
  color: $gray-dark;
}

.unpublishedAlert {
  width: 90%;
  height: 70px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  p {
    color: #999999;
    margin-bottom: 0;
    margin-left: 24px;
    a {
      color: #a8947d !important;
      cursor: pointer;
    }
  }
  svg {
    height: 40px !important;
    width: 40px !important;
    color: #999999;
  }
}

.communityPopups {
  width: 750px;
  background-color: #fff;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .popupHeader {
    .headerDescription {
      color: #999999;
      margin-bottom: 30px;
    }
  }
  .checklist {
    .item {
      margin: 0px 0px 30px 40px;
      display: flex;
      align-items: center;
      .checklistImage {
        margin: 0 15px;
      }

      .checklistDescription {
        font-size: 16px;
      }
    }
  }

  .checklistButtons {
    display: flex;
    justify-content: space-between;

    button {
      width: 125px;
    }
  }
}

.copyCommunityPopup {
  height: 380px;
  justify-content: space-between;

  .popupContent {
    margin-bottom: 10px;
  }
}

.conflictPrompt {
  background-color: $whitest;
  min-width: 600px;
  padding: 35px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .closeButton {
      cursor: pointer;
      color: $kw-colors-sand-dark;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  .message {
    margin-bottom: 24px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
