@import '../../styles/_settings';

.communityListContainer {
  .communityNameContainer {
    display: flex;
    flex-direction: row;
    color: #999999 !important;
    align-items: center;

    .communityNameInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      b {
        font-weight: $font-weight-bold;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #222222 !important;
      }
      p {
        color: #999999;
        margin-bottom: 0;

        svg {
          color: $kw-colors-sand-darker;
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  table {
    margin-top: 25px;
    width: 100%;
    color: #999999 !important;

    thead {
      border-bottom: $black 1px solid;
      tr {
        padding: 0 10px 10px 10px;
        justify-content: space-between;

        th {
          color: $black;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      background-color: $white;

      .communityItem {
        min-height: 110px;
        border-bottom: 1px $sand-dark-opaque solid;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
        &:nth-child(even) {
          background-color: $sand-dark-opaque;
        }
        &:hover {
          background-color: rgba($kw-colors-sand-dark, 0.4);
        }

        td {
          align-self: center;
        }
      }
      .expandableInfoOpen {
        display: flex;
        height: 105px;
        padding: 25px;
        background-color: $sand-dark-opaque;
        align-items: center;
      }

      .expandableInfoClose {
        display: none;
      }
    }
  }
}
