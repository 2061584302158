@import './_settings';

.list {
  .loadMoreButton {
    margin: 50px auto;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    min-height: 70px;
    &:first-of-type {
      padding-top: 0px;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-lighter;
    }
    .itemInfoContainer {
      display: flex;

      img {
        height: 80px;
        width: 80px;
        padding: 15px 0;
        margin-right: 15px;
        box-sizing: content-box;
      }
      .itemInfo {
        font-weight: 400;
        .titleRequested {
          font-family: Helvetica, $font-family-sans;
          padding-top: 15px;
          font-size: 18px;
          margin-bottom: 0px;
          color: #a8947d;

          .hasRequested {
            color: #a8947d;
            font-weight: 400;
            cursor: pointer;
          }
          span {
            color: #999999;
            font-weight: 400;
          }
        }
        .address {
          margin: 0;
          color: #999999;
        }
        .created {
          padding-bottom: 5%;
          color: #999999;
        }
        .container_info {
          margin-top: 2%;
          .infoMessages {
            font-family: Helvetica, $font-family-sans;
            font-size: 16px;
            margin-bottom: 0px;
            color: #999999;
            p {
              color: #a8947d;
            }
          }
        }
        .containerButtons {
          display: flex;
          margin-top: 4%;
          .pCallInfo {
            color: #999999;
            text-align: center;
            font-size: 12px;
          }
          .pEmailInfo {
            color: #999999;
            font-size: 12px;
            text-align: center;
            flex-wrap: wrap;
            margin-left: 22%;
            width: 100%;
          }
          .emailButton {
            margin-left: 15%;
            width: 100%;
          }
        }
        .infoGrid {
          display: grid;
          grid-template-columns: 80px 1fr;
        }
      }
    }
  }
}
