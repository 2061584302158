@import '../../styles/_settings';

.communityEditsList {
  .loadMoreButton {
    margin: 50px auto;
  }
}

.textArea {
  width: 100%;
}
