@import '../../styles/settings';

.moderationNewUser {
  padding: 0 40px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    border-bottom: 1px solid $gray-lighter;

    h2 {
      font-size: 2em;
      line-height: 2.3125em;
      font-family: Helvetica, $font-family-sans;
      margin: 0;
    }

    .searchContainer {
      display: flex;
      position: relative;
      align-items: center;

      input {
        padding-right: 45px;
      }

      button {
        position: absolute;
        right: 0;
        color: black;
        align-self: center;
        height: 70%;
        padding: 10px;
        margin-right: 5px;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 585px;
    gap: 45px;
    padding: 25px;

    label {
      align-self: flex-start;
    }

    .rowGroup {
      display: grid;
      justify-content: space-between;
      gap: 20px;
      grid-template-columns: repeat(2, minmax(50px, 1fr));
    }

    .fieldGroup {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .organizationOriginWrapper {
      display: flex;
      flex-direction: column;

      .originRadioGroup {
        display: flex;
        flex-direction: row;
        gap: 50px;
      }
    }
  }
}
