// scss-lint:disable ImportantRule, SpaceBeforeBrace, PropertySortOrder, SingleLinePerSelector
// scss-lint:disable SpaceAfterPropertyColon, LeadingZero, QualifyingElement, SelectorFormat, DeclarationOrder,

@import './settings';

// -------- New styles (Admin HFC) ---------

input,
textarea {
  color: $black;
  height: 45px;
  background-color: $whitest;
  padding: 15px 20px 12px 20px;
  outline: none;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-base;
  font-family: $font-family-sans;
  font-weight: $font-weight-bold;
  font-size: 0.875rem;

  &.bordered {
    border: 1px solid $black;
  }
  &.black {
    background-color: $black;
    color: $white;
    border: 1px solid $gray-darkest;
    &::placeholder {
      color: $gray-light;
    }
  }
  &:focus {
    // border: 1px solid $control-color;
  }

  &::placeholder {
    color: $gray-darker;
  }

  &.error {
    border: 1px solid $danger-color;

    &:focus {
      border: 1px solid $danger-color;
    }
  }
}

textarea {
  height: 80px;
}

label {
  font-family: $font-family-sans;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 0.875rem;
  letter-spacing: $letter-spacing-title;
  text-transform: uppercase;
  color: $black;
  align-self: center;

  &.required::after {
    content: '*';
  }
}
