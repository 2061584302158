@import './settings';

.popupForm {
  width: 60vw;
  background-color: #fff;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  border-radius: 5px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid $black;
    h3 {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .tabDescription {
    font-family: $font-family-sans;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }

  .popupFormSection {
    border-bottom: 1px solid $black;
    flex: 1;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.bottomButtons {
  padding-top: 20px;
  padding-bottom: 20px;
  button {
    height: 40px;
    min-width: 40px;
  }
}
