@import '../../../styles/settings';

.searchAndClaimPage {
  .claimButton {
    height: 40px;
    width: 200px;
    align-items: center;
    display: flex;
    justify-content: center;
    button {
      width: 160px;
    }
  }
}
