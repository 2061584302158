@import '../../styles/_settings';

.tabBarContainer {
  .tabBar {
    min-height: 50px;
    line-height: 40px;
    text-align: right;
    background-color: $black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 50px;
    flex-wrap: wrap;

    .tabs {
      display: flex;
      flex-direction: row;

      .tab {
        color: $white;
        display: flex;
        align-items: center;
        margin: 0 10px;
        position: relative;
        cursor: pointer;
        font-family: Helvetica, $font-family-sans;
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        line-height: 14px;
        text-align: center;
        letter-spacing: $letter-spacing-title;
        text-transform: uppercase;
        &:after {
          position: absolute;
          bottom: 0px;
          left: 50%;
          height: 2px;
          background-color: #fff;
          width: 0%;
          transform: translateX(-50%);
          display: block;
          content: '';
          transition: width 200ms ease-in-out;
          transition-property: background-color, width;
        }
        &:hover {
          &:after {
            width: 100%;
            background: #fff;
          }
        }
        .tabTitle {
          white-space: nowrap;
        }
      }
      .selectedTab {
        &:after {
          width: 100%;
          background-color: $white;
        }
      }
    }
  }
}
