@import '../../styles/_settings';

.queueActionButtonContainer {
  display: flex;
  flex-direction: column;
  width: 120px;
  min-width: 120px;
  align-items: center;

  .actionStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    color: $whitest;
    width: 100%;
    text-align: center;
    font-size: 0.625rem;
    line-height: 0.6875rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-family: Helvetica, $font-family-sans;
    font-weight: 400;
  }

  .actionButtonsContainer {
    width: 100%;

    .actionButton {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      color: $kw-colors-sand-dark;
      border-color: $kw-colors-sand-dark $kw-colors-sand-dark $kw-colors-sand-dark
        $kw-colors-sand-dark;
      border-style: solid solid solid solid;
      border-width: 0px 1px 1px 1px;
      cursor: pointer;
      &:first-of-type {
        background-color: $kw-colors-sand-dark;
        border-radius: 4px 4px 0px 0px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto;
        color: $white;
        cursor: pointer;
      }
    }
  }
}
