@import '../../../../styles/_settings';

.rejectPopup {
  background-color: $whitest;
  padding: 20px 36px;
  min-width: 650px;

  .header {
    display: flex;
    justify-content: space-between;

    .closeButton {
      color: $kw-colors-sand-dark;
      cursor: pointer;
      background: inherit;
      border: none;
      outline: none;
      padding: 0;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  .rejectPrompt {
    color: #666666;
  }

  .textArea {
    width: 350px;
  }

  .silentRejectFlag {
    margin: 12px 0;
  }

  .bottomButton {
    display: flex;
    justify-content: center;
  }
}
