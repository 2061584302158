@import '../../styles/_settings';

.rejectPopup {
  h4 {
    font-size: 1.25rem;
    font-family: Helvetica, $font-family-sans;
  }

  textarea {
    width: 100%;
  }

  .rejectButton {
    margin: 30px auto 0;
  }
}
