@import '../../styles/settings';

.ratingPopupContainer {
  background-color: $whitest;
  padding: 24px 28px;
  min-width: 650px;

  .header {
    display: flex;
    position: relative;

    .closeButton {
      color: $kw-colors-sand-dark;
      cursor: pointer;
      background: inherit;
      border: none;
      outline: none;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  .confirmButton {
    display: flex;
    justify-content: center;
  }
}
