@import '../../styles/settings';

.containerModule {
  margin-top: 25px;
  padding: 80px;
  border: solid 1px rgb(234, 230, 224);
  .title {
    font-family: $font-family-serif;
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: left;
  }

  .subTitle {
    font-family: $font-family-extended;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 1px;
    text-align: left;
    color: #cab29a;
    text-transform: uppercase;
  }

  .description {
    font-family: $font-family-extended;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 1px;
    text-align: left;
  }
}
