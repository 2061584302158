.upsellPage {
  h1 {
    font-family: Chronicle Display, serif;
    font-size: 40px;
    letter-spacing: -1.75px;
    text-align: center;
    margin-bottom: 0;
  }
  h4 {
    font-family: Maison Neue, serif;
    font-size: 20px;
    letter-spacing: -1px;
    text-align: center;
    padding-bottom: 8px;
  }
  table {
    border: 2px solid black !important;
    width: 100%;
    td {
      border-left: 1px solid black;
      font-family: Maison Neue Extended, serif;
      text-align: center;

      h5 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -1.75px;
        text-align: center;
      }

      h6 {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-align: left;
        margin-bottom: 2px;
      }

      p {
        font-family: Maison Neue, serif;
        font-size: 14px;
        text-align: left;
      }

      .faCheck {
        font-size: 28px !important;
      }

      .faStar {
        font-size: 14px !important;
        margin-right: 4px;
      }
    }

    td:first-child {
      border-left: none;
      padding: 1em 2em;
      width: 50%;
    }
  }
}

.adminUserSeats {
  font-family: Maison Neue, serif;
  font-size: 20px;
}

.newResident {
  font-family: Maison Neue, serif;
  font-size: 14px;
}

.price {
  font-family: Chronicle Display, serif;
  font-size: 30px;
}

.priceText {
  font-family: Maison Neue Extended, serif;
  font-size: 12px;
  font-weight: 700;
  margin-top: 2px;
}
