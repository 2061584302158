@import '../../../../../styles/settings';
@import '../../../../../styles/popup';

.headerWithSub {
  display: flex;
  flex-direction: column !important;
}

.formBody {
  display: flex;
  flex-direction: column;
  max-width: 60vw;
  background: #ffffff;

  h4 {
    text-align: center;
  }

  .communityImages {
    margin: 0.5em 0;
    padding-bottom: 1em;
    border-bottom: 1px solid #222;

    .communityImagesContainer {
      display: flex;
      max-width: 60vw;
      overflow: scroll;
      min-height: 120px;

      .emptyBox {
        font-size: $font-size-large;
        align-self: center;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .communityImageContainer {
        padding: 0 0.5em;

        //PDFRenderer
        div {
          height: 120px;
          min-width: 120px;
        }

        img:hover {
          transition: 0.3s ease-in-out;
          transition-delay: 0.2s;
          box-shadow: 0 0 2px 2px $kw-colors-sand-dark;
        }
      }
    }
  }

  .restaurantImages {
    padding-bottom: 2em;
    border-bottom: 1px solid #222;

    .diningImagesContainer {
      display: flex;
      position: relative;
      max-width: 60vw;
      overflow: scroll;
      min-height: 120px;

      .diningImageContainer:hover {
        transition: 0.3s ease-in-out;
        transition-delay: 0.3s;
        box-shadow: 0 0 2px 2px $kw-colors-sand-dark;
      }

      .diningImageContainer:hover > .cancelButton {
        opacity: 0.9;
        transition: 0.3s ease-in-out;
        transition-delay: 0.3s;
      }

      .diningImageContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0em 0.5em;

        //PDFRenderer
        div {
          height: 120px;
          min-width: 120px;
        }
        img {
          height: 120px;
          min-width: 120px;
          object-fit: contain;
        }

        .cancelButton {
          opacity: 0;
        }

        .cancelButton {
          position: absolute;
          height: 35px;
          left: calc(100% - 35px);
          width: 35px;
          z-index: 99;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
