@import '../../styles/_settings';
@import '../../styles/_consumerAdmin';

.infoUnit {
  padding-top: 15px;
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 500;
  font-family: Helvetica, $font-family-sans;
  color: #a8947d;
  span {
    color: #999999;
  }
}
.infoDates {
  font-family: Helvetica, $font-family-sans;
  padding-top: 15px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #999999;
  p {
    font-weight: 500;
    color: #a8947d;
  }
}
