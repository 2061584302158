@import '../../../../styles/_settings';

.popupContainer {
  background-color: $whitest;
  padding: 20px 36px;
  min-width: 650px;

  .header {
    display: flex;
    justify-content: space-between;

    .closeButton {
      color: $kw-colors-sand-dark;
      cursor: pointer;
      background: inherit;
      border: none;
      outline: none;
      padding: 0;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  .confirmPrompt {
    color: #666666;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .publishedRating,
    .draftRating {
      display: grid;
      grid-template-columns: 25% 75%;
      align-items: center;

      .ratingWrapper {
        width: fit-content;
      }

      &:after {
        content: '';
        grid-row: 2;
        grid-column: 1;
      }

      p {
        margin-bottom: 0;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      .overrideReasonNote {
        min-height: 100px;
      }

      .show {
        opacity: 1;
        scale: 1;
        transition: opacity 0.3s ease-in-out;
      }

      .hidden {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
      }
    }

    hr {
      margin: 12px 0;
    }
  }

  .bottomButton {
    display: flex;
    justify-content: center;
  }
}
