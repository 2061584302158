@import '../../styles/_settings';

.searchTableContainer {
  width: 100%;
  margin-top: 25px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      border-bottom: $black 1px solid;

      tr {
        padding: 0 10px 10px 10px;
        justify-content: space-between;

        th {
          text-transform: uppercase;
          text-align: left;
          padding: 10px;
        }
      }
    }

    tbody {
      background-color: $white;

      .searchItem {
        height: auto;
        min-height: 93px;
        border-bottom: 1px solid $sand-dark-opaque;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          background-color: $sand-dark-opaque;
        }

        td {
          padding: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}

.searchTable {
  width: 100%;
}

.searchTable th,
.searchTable td {
  padding: 10px;
  text-align: left;
}

.searchTable thead th {
  border-bottom: 2px solid $black;
}

.tableContent {
  white-space: normal !important;
}

.userName {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.userEmail {
  text-transform: none;
  font-weight: $font-weight-normal;
}

.emailPending {
  text-transform: uppercase;
  font-weight: $font-weight-light;
  font-size: 0.625rem;
  color: $gray-dark;
}

.tableButtons {
  display: flex;
  flex-direction: column;
  align-items: center;

  >button {
    width: 180px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}